import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import ManagerAddEditForm from "./ManagerAddEditForm";

const ManagerPage = () => {
  const [managers, setManagers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentManager, setCurrentManager] = useState({});
  const [filter, setFilter] = useState("");
  const searchRef = useRef();

  useEffect(() => {
    fetchManagers();
  }, []);

  const fetchManagers = async () => {
    setLoading(true);
    try {
      const response = await axios.get("http://localhost:8089/api/v1/manager/getall");
      setManagers(response.data);
    } catch (error) {
      console.error("Error fetching managers:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = () => {
    setCurrentManager({});
    setEditMode(false);
    setOpen(true);
  };

  const handleClose = (refresh = false) => {
    setOpen(false);
    if (refresh) {
      fetchManagers();
    }
  };

  const handleEdit = (manager) => {
    setCurrentManager(manager);
    setEditMode(true);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`http://localhost:8089/api/v1/manager/delete/${id}`);
      fetchManagers();
      alert("Manager  supprimé avec succès");
    } catch (error) {
      console.error("Erreur lors de la suppression du manager:", error);
      alert("Erreur lors de la suppression du manager");
    }
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredManagers = managers.filter(
    (manager) =>
      (manager.utilisateur.prenom || '').toLowerCase().includes(filter.toLowerCase()) ||
      (manager.utilisateur.nom || '').toLowerCase().includes(filter.toLowerCase()) ||
      (manager.utilisateur.email || '').toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div className="flex flex-col p-5">
      {loading ? (
        <div className="flex justify-center items-center h-full">
          <span className="loader"></span> {/* Add your loading spinner here */}
        </div>
      ) : (
        <>
          <div className="flex justify-between items-center bg-gray-100 p-4 rounded-lg">
            <h2 className="text-2xl font-bold text-gray-700">Managers</h2>
            <button
              onClick={handleOpen}
              className="bg-gray-700 text-white p-2 rounded hover:bg-blue-600"
            >
              Nouveau Manager
            </button>
          </div>
          <div className="mb-5 flex items-center">
            <input
              ref={searchRef}
              placeholder="Rechercher par nom"
              className="border border-gray-300 p-2 rounded w-full"
              onChange={handleFilterChange}
            />
          </div>
          <div className="overflow-y-auto max-h-[calc(100vh-100px)]">
            <table className="min-w-full border-collapse border border-gray-200">
              <thead>
                <tr className="bg-gray-100">
                  <th className="border-b p-2">ID</th>
                  <th className="border-b p-2">Prénom</th>
                  <th className="border-b p-2">Nom</th>
                  <th className="border-b p-2">Email</th>
                  <th className="border-b p-2">Téléphone</th>
                  <th className="border-b p-2">Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredManagers.length === 0 ? (
                  <tr>
                    <td colSpan="6" className="text-center py-4">No managers found</td>
                  </tr>
                ) : (
                  filteredManagers.map((manager) => (
                    <tr key={manager.id} className="border-b hover:bg-gray-100">
                      <td className="p-2">{manager.id}</td>
                      <td className="p-2">{manager.utilisateur.prenom}</td>
                      <td className="p-2">{manager.utilisateur.nom}</td>
                      <td className="p-2">{manager.utilisateur.email}</td>
                      <td className="p-2">{manager.utilisateur.numTel}</td>
                      <td className="p-2 flex space-x-2">
                        <button
                          onClick={() => handleEdit(manager)}
                           className="text-blue-500 hover:bg-blue-100 p-2 rounded"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.828 4.586l4.586 4.586-1.414 1.414-4.586-4.586 1.414-1.414zM3 21v-3.586l10-10 3.586 3.586-10 10H3z" />
                          </svg>
                        </button>
                        <button
                          onClick={() => handleDelete(manager.id)}
                          className="text-red-500 hover:bg-red-100 p-2 rounded"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7L7 19M7 7l12 12" />
                          </svg>
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          {open && (
            <ManagerAddEditForm
              manager={editMode ? currentManager : null}
              visible={open}
              onClose={handleClose}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ManagerPage;
