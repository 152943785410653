import React, { useEffect, useState } from 'react';
import ManagerService from '../../../services/ManagerService';

const ManagerAddEditForm = ({ manager, visible, onClose }) => {
  const [formData, setFormData] = useState({
    managerPrenom: '',
    managerNom: '',
    managerEmail: '',
    managerNumTel: '',
    managerMotDePasse: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (manager) {
      setFormData({
        managerPrenom: manager.utilisateur.prenom,
        managerNom: manager.utilisateur.nom,
        managerEmail: manager.utilisateur.email,
        managerNumTel: manager.utilisateur.numTel,
        managerMotDePasse: '', // Keep empty to avoid showing existing password
      });
    } else {
      setFormData({
        managerPrenom: '',
        managerNom: '',
        managerEmail: '',
        managerNumTel: '',
        managerMotDePasse: '',
      });
    }
  }, [manager]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      if (manager) {
        // Update existing manager
        await ManagerService.updateManager(manager.id, formData);
        alert('Manager mis à jour avec succès');
      } else {
        // Add new manager
        await ManagerService.addManager(formData); // Matches the DTO structure
        alert('Manager ajouté avec succès');
      }
      onClose(true);
    } catch (error) {
      console.error('Error saving manager:', error);
      setError(error.response?.data?.message || 'An unexpected error occurred');
    } finally {
      setLoading(false);
    }
  };

  if (!visible) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-500 bg-opacity-75">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-lg max-h-[80vh] p-8 overflow-y-auto">
        <h2 className="text-xl font-semibold mb-4">{manager ? 'Modifier Manager' : 'Ajouter Manager'}</h2>
        {error && <div className="text-red-500 mb-4">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Prénom</label>
            <input
              type="text"
              name="managerPrenom"
              value={formData.managerPrenom}
              onChange={handleChange}
              required
              className="border border-gray-300 p-2 rounded w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Nom</label>
            <input
              type="text"
              name="managerNom"
              value={formData.managerNom}
              onChange={handleChange}
              required
              className="border border-gray-300 p-2 rounded w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Email</label>
            <input
              type="email"
              name="managerEmail"
              value={formData.managerEmail}
              onChange={handleChange}
              required
              className="border border-gray-300 p-2 rounded w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Téléphone</label>
            <input
              type="tel"
              name="managerNumTel"
              value={formData.managerNumTel}
              onChange={handleChange}
              required
              className="border border-gray-300 p-2 rounded w-full"
            />
          </div>
          {!manager && (
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">Mot de passe</label>
              <input
                type="password"
                name="managerMotDePasse"
                value={formData.managerMotDePasse}
                onChange={handleChange}
                required
                className="border border-gray-300 p-2 rounded w-full"
              />
            </div>
          )}
          <div className="flex justify-end">
            <button
              type="button"
              onClick={() => onClose(false)}
              className="ml-2 border bg-gray-400 px-4 py-2 rounded hover:bg-gray-200 transition"
            >
              Annuler
            </button>
            <button
              type="submit"
              className="bg-gray-700 text-white px-4 py-2 rounded hover:bg-gray-800 transition"
              disabled={loading}
            >
              {loading ? 'Enregistrement...' : manager ? 'Modifier' : 'Enregistrer'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ManagerAddEditForm;
