// src/routes.js
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage/LoginPage';
import ResetPasswordPage from './pages/ResetPasswordPage/ResetPasswordPage';
import TimesheetPage from './pages/TimesheetPage/TimesheetPage';
import MissionPage from './pages/MissionPage/MissionPage';
import CompanyPage from './pages/CompanyPage/CompanyPage.js';
import InvoicePage from './pages/InvoicePage/InvoicePage';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import DashboardPage from './pages/DashboardPage/DashboardPage';
import Layout from './components/Layout';
import AdminPage from './pages/UsersPages/AdminPage/AdminPage';
import ManagerPage from './pages/UsersPages/ManagerPage/ManagerPage'; 
import SupplierAdminPage from './pages/UsersPages/SupplierAdminPage/SupplierAdminPage.js';
import CollaboratorPage from './pages/UsersPages/CollaboratorPage/CollaboratorPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage/ForgotPasswordPage'; 
import NoteFraisPage from './pages/NoteFraisPage/NoteFraisPage.js'
import ManagerTimesheetPage from './pages/ManagerTimesheetPage/ManagerTimesheetPage';
import ManagerNotesPage from './pages/ManagerNotesPage/ManagerNotesPage.js';
import ProtectedRoute from './components/ProtectedRoute';
import NoteInvoicePage from './pages/NoteInvoicePage/NoteInvoicePage.js';
import SupplierNoteInvoicePage from './pages/SupplierNoteInvoicesPage/SupplierNoteInvoicePage.js';
import SupplierTimesheetInvoicePage from './pages/SupplierTimesheetsInvoicePage/SupplierTimesheetInvoicePage.js';
import RegisterPage from './pages/RegisterPage/RegisterPage.js';

function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={< Navigate to= "/Login" />} />
            <Route path="/" element={<Layout />}>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/register" element={<RegisterPage />} />                
                <Route path="dashboard" element={<ProtectedRoute allowedRoles={['ADMIN', 'MANAGER', 'COLLABORATEUR','SUPPLIER_ADMIN']}><DashboardPage /></ProtectedRoute>} />
                <Route path="/reset-password" element={<ProtectedRoute allowedRoles={['ADMIN', 'MANAGER','COLLABORATEUR','SUPPLIER_ADMIN']}><ResetPasswordPage /></ProtectedRoute>}/>
                <Route path="/timesheet" element={<ProtectedRoute allowedRoles={['COLLABORATEUR', 'MANAGER']}><TimesheetPage /></ProtectedRoute>} />
                <Route path="/timesheets" element={<ProtectedRoute allowedRoles={['ADMIN','MANAGER']}><ManagerTimesheetPage /></ProtectedRoute>} />
                <Route path="/mission" element={<ProtectedRoute allowedRoles={['ADMIN', 'MANAGER']}><MissionPage /></ProtectedRoute>} />
                <Route path="/users/admin" element={<ProtectedRoute allowedRoles={['ADMIN']}><AdminPage /></ProtectedRoute>} />
                <Route path="/users/manager" element={<ProtectedRoute allowedRoles={['ADMIN']}><ManagerPage /></ProtectedRoute>} />
                <Route path="/users/supplierAdmin" element={<ProtectedRoute allowedRoles={['ADMIN', 'MANAGER']}><SupplierAdminPage /></ProtectedRoute>} />
                <Route path="/users/collaborator" element={<ProtectedRoute allowedRoles={['ADMIN', 'MANAGER']}><CollaboratorPage /></ProtectedRoute>} />
                <Route path="/forgot-password" element={<ProtectedRoute allowedRoles={['ADMIN', 'MANAGER','COLLABORATEUR','SUPPLIER_ADMIN']}><ForgotPasswordPage /></ProtectedRoute>}/>
                <Route path="/company" element={<ProtectedRoute allowedRoles={['ADMIN', 'MANAGER']}><CompanyPage /></ProtectedRoute>} />
                <Route path="/invoice" element={<ProtectedRoute allowedRoles={['MANAGER', 'COLLABORATEUR']} freelancerOnly={true}><InvoicePage /></ProtectedRoute>} />
                <Route path="/noteInvoice" element={<ProtectedRoute allowedRoles={['MANAGER', 'COLLABORATEUR']} freelancerOnly={true}><NoteInvoicePage /></ProtectedRoute>} />    
                <Route path="/profile" element={<ProtectedRoute allowedRoles={['ADMIN', 'MANAGER','COLLABORATEUR','SUPPLIER_ADMIN']}><ProfilePage /></ProtectedRoute>} />
                <Route path="/noteFrais" element={<ProtectedRoute allowedRoles={['COLLABORATEUR']}><NoteFraisPage /></ProtectedRoute>} />
                <Route path="/notes" element={<ProtectedRoute allowedRoles={['ADMIN','MANAGER']}><ManagerNotesPage /></ProtectedRoute>} />
                <Route path="/supplierNoteInvoice" element={<ProtectedRoute allowedRoles={['SUPPLIER_ADMIN']}><SupplierNoteInvoicePage /></ProtectedRoute>} />
                <Route path="/supplierTimesheetInvoice" element={<ProtectedRoute allowedRoles={['SUPPLIER_ADMIN']}><SupplierTimesheetInvoicePage /></ProtectedRoute>} />
            </Route>
        </Routes>
    );
}

export default AppRoutes;
