import React from 'react';
import { Link } from 'react-router-dom';

const SublevelMenu = ({ data, collapsed }) => {
  return (
    <ul className={`pl-4 w-[90%] text-center`}>
      {data.items.map((subItem, index) => (
        <li key={index} className="my-2">
          <Link
            to={`/${subItem.routeLink}`}
            className="no-underline text-gray-400 flex items-center justify-center p-2 rounded-lg transition duration-300 ease-in-out hover:bg-gray-700 w-full"
          >
            {subItem.icon} {/* Render sub-item icon here */}
            <span className={`ml-2 ${collapsed ? 'hidden' : 'whitespace-nowrap'}`}>
              {subItem.label}
            </span>
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default SublevelMenu;
