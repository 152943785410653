import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../../services/AuthService';
import { useAuth } from '../../context/AuthContext';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoginFailed, setIsLoginFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const navigate = useNavigate();
  const { login } = useAuth(); // Ensure useAuth provides login function

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);

    if (!email || !password) {
      return;
    }

    try {
      // Use authService to handle login
      await authService.login(email, password);
      login(); // Call login function from context
      setIsLoginFailed(false);
      navigate('/dashboard');
    } catch (error) {
      console.error('Login error:', error);
      setErrorMessage(error.response?.data?.message || 'Authentication failed. Please try again.');
      setIsLoginFailed(true);
    }
  };

  return (
    <div className="bg-white min-h-screen flex items-center justify-center">
      <div className="container mx-auto p-4 grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="hidden md:flex justify-end items-center">
          <img src="/img/bg.svg" alt="Background" className="w-3/4" />
        </div>
        <div className="flex flex-col justify-center items-center text-center">
          <form onSubmit={handleSubmit} noValidate className="w-full max-w-md">
            <img src="/img/logoTime.jpg" alt="Logo" className="h-32 mx-auto" />
            <h2 className="text-3xl font-bold text-gray-800 my-4">Bienvenue</h2>
            <div className="relative mb-6">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                <i className="fas fa-user text-gray-400"></i>
              </div>
              <input
                type="email"
                className="w-full pl-10 pr-4 py-2 border-b-2 border-gray-300 focus:border-blue-500 outline-none"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="Email"
              />
              {submitted && email === '' && (
                <div className="text-red-500 text-sm mt-1" role="alert">
                  <span>L'email est requis!</span>
                </div>
              )}
            </div>
            <div className="relative mb-6">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                <i className="fas fa-lock text-gray-400"></i>
              </div>
              <input
                type="password"
                className="w-full pl-10 pr-4 py-2 border-b-2 border-gray-300 focus:border-blue-500 outline-none"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                minLength="6"
                placeholder="Mot de passe"
              />
              {submitted && (
                <div className="text-red-500 text-sm mt-1" role="alert">
                  {password === '' && <div>Mot de passe est requis</div>}
                  {password.length < 6 && <div>Le mot de passe doit comporter au moins 6 caractères</div>}
                </div>
              )}
            </div>
            <div className="mb-4">
              <a href="/forgot-password" className="text-blue-500 hover:underline">Mot de passe oublié?</a>
            </div>
            <div className="mb-4">
              <button className="w-full py-2 bg-blue-500 text-white font-bold rounded hover:bg-blue-600 transition duration-300" type="submit">Connexion</button>
            </div>
            {isLoginFailed && (
              <div className="text-red-500 text-sm mt-1" role="alert" aria-live="assertive">
                <span>Échec de l'authentification: {errorMessage}</span>
              </div>
            )}
             <div className="mt-4 text-sm">
              <span>Pas encore de compte ? </span>
              <button 
                type="button"
                onClick={() => navigate('/register')}
                className="text-blue-500 hover:underline"
              >
                S'inscrire
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
