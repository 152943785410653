import axios from 'axios';
const API_URL ='http://localhost:8077/api/invoice';

const InvoiceService = {

    getInvoicesList : async (email) => {
        try {
          const response = await axios.get(`${API_URL}/email/${email}`);

          return response.data;
        } catch (error) {
          console.error('Error fetching invoices list:', error);
          throw error;
        }
      },

      getsupplierInvoicesList : async (email) => {
        try {
          const response = await axios.get(`${API_URL}/supplierAdmin/${email}`);

          return response.data;
        } catch (error) {
          console.error('Error fetching invoices list:', error);
          throw error;
        }
      },


      deleteInvoice: async (invoiceId) => {
        try {
          const response = await axios.delete(`${API_URL}/delete/${invoiceId}`);
          return response.data;
        } catch (error) {
          console.error(`Error deleting invoice ${invoiceId}`, error);
          throw error;
        }
      },

};

export default InvoiceService;