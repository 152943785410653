import React, { useEffect, useState } from 'react';
import CollaboratorService from '../../../services/CollaboratorService';
import ManagerService from '../../../services/ManagerService';

const CollaboratorAddEditForm = ({ collaborator, onClose, editMode }) => {
  const [formData, setFormData] = useState({
    prenom: collaborator?.utilisateur?.prenom || '', 
    nom: collaborator?.utilisateur?.nom || '',
    email: collaborator?.utilisateur?.email || '',
    numTel: collaborator?.utilisateur?.numTel || '',
    motDePasse: '', 
    managerId: collaborator?.manager?.id || '' ,
    freelancer:false,
  });

  const [managers, setManagers] = useState([]);

  useEffect(() => {
    const fetchManagers = async () => {
      try {
        const response = await ManagerService.getManagers();
        setManagers(response.data);
      } catch (error) {
        console.error('Error fetching managers:', error);
      }
    };
    fetchManagers();
  }, []);

  useEffect(() => {
    if (collaborator) {
      setFormData({
        prenom: collaborator.utilisateur?.prenom,
        nom: collaborator.utilisateur?.nom,
        email: collaborator.utilisateur?.email,
        numTel: collaborator.utilisateur?.numTel,
        motDePasse: '', // Reset password for security
        managerId: collaborator.manager ? collaborator.manager.id : '',
        freelancer: collaborator.freelancer || false,
      });
    }
  }, [collaborator]);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (editMode) {
        await CollaboratorService.updateCollaborator(collaborator.id, formData);
      } else {
        await CollaboratorService.addCollaborator(formData);
      }
      onClose(true);
    } catch (error) {
      console.error('Error saving collaborator:', error);
      // You may want to show an error message to the user here
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-1/3 p-6 max-h-[90vh] overflow-y-auto">
        <h2 className="text-xl font-bold mb-4">{editMode ? 'Modifier Collaborateur' : 'Ajouter Collaborateur'}</h2>
        <form onSubmit={handleSubmit}>
          {/* Form Fields */}
          {['prenom', 'nom', 'email', 'numTel', 'motDePasse'].map((field) => (
            <div className="mb-4" key={field}>
             <label htmlFor={field} className="block mb-2">{field.charAt(0).toUpperCase() + field.slice(1)}</label>
                <input
                  id={field} // Associate input with label
                  name={field}
                  type={field === 'motDePasse' ? 'password' : 'text'}
                  value={formData[field]}
                  data-testid={field}
                  onChange={handleChange}
                  required={field !== 'motDePasse' || !editMode}
                  className="border border-gray-300 rounded-md p-2 w-full"
                />
            </div>
          ))}
          {/* Manager Selection */}
          <div className="mb-4">
            <label className="block mb-2">Manager</label>
            <select
              name="managerId"
              value={formData.managerId}
              onChange={handleChange}
              required
              className="border border-gray-300 rounded-md p-2 w-full"
              aria-label="Manager"
            >
              <option value="">Select Manager</option>
              {managers.map((manager) => (
                <option key={manager.id} value={manager.id}>
                  {manager.utilisateur.prenom} {manager.utilisateur.nom}
                </option>
              ))}
            </select>
          </div> 
          <label className="flex items-center mb-4 ml-4">
            <input
              type="checkbox"
              name="freelancer"
              checked={formData.freelancer}
              onChange={handleChange}
              className="mr-2"
              aria-label="Freelancer"
            />
            Freelancer
          </label>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={() => onClose(false)}
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md mr-2"
            >
              Annuler
            </button>
            <button
              type="submit"
              className="bg-blue-600 text-white px-4 py-2 rounded-md"
            >
              {editMode ? 'Modifier' : 'Ajouter'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CollaboratorAddEditForm;