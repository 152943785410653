// src/components/Cards.js
import React, { useEffect, useState } from 'react';
import MissionService from '../../services/MissionService'; // Import the whole service
import { getCollaboratorCount } from '../../services/CollaboratorService';
import { getNotificationCount } from '../../services/NotificationService';

const Cards = () => {
    const [notificationCount, setNotificationCount] = useState(0);
    const [missionCount, setMissionCount] = useState(0);
    const [collaboratorCount, setCollaboratorCount] = useState(0);

    useEffect(() => {
        // Fetch Notification Count
        getNotificationCount()
            .then(response => setNotificationCount(response.data))
            .catch(error => console.error('Error fetching notification count:', error));

        // Fetch Mission Count
        MissionService.getMissionCount() // Use the imported service
            .then(response => setMissionCount(response)) // Adjust based on the response structure
            .catch(error => console.error('Error fetching mission count:', error));

        // Fetch Collaborator Count
        getCollaboratorCount()
            .then(response => setCollaboratorCount(response.data))
            .catch(error => console.error('Error fetching collaborator count:', error));
    }, []);

    return (
        <div className="flex flex-wrap -mx-2">
            {/* Notification Card */}
            <div className="w-full sm:w-1/3 px-2 mb-4">
                <div className="bg-slate-400 text-black rounded-lg shadow-lg p-4 transition-transform transform hover:translate-y-1">
                    <div className="flex items-center">
                        <span className="material-icons text-4xl">Notifications</span>
                        <div className="ml-4">
                            <div className="text-2xl font-bold">{notificationCount}</div>
                            <p>Nouveaux Notification !</p>
                        </div>
                    </div>
                    <a className="block mt-4 text-black-200 hover:text-blue-100" href="/notification">
                        <span>Plus de détails</span>
                    </a>
                </div>
            </div>

            {/* Mission Card */}
            <div className="w-full sm:w-1/3 px-2 mb-4">
                <div className="bg-slate-200 text-black rounded-lg shadow-lg p-4 transition-transform transform hover:translate-y-1">
                    <div className="flex items-center">
                        <span className="material-icons text-4xl">Missions</span>
                        <div className="ml-4">
                            <div className="text-2xl font-bold">{missionCount}</div>
                            <p>Mission en cours !</p>
                        </div>
                    </div>
                    <a className="block mt-4 text-black-200 hover:text-blue-100" href="/mission">
                        <span>Plus de détails</span>
                    </a>
                </div>
            </div>

            {/* Collaborator Card */}
            <div className="w-full sm:w-1/3 px-2 mb-4">
                <div className="bg-slate-300 text-black rounded-lg shadow-lg p-4 transition-transform transform hover:translate-y-1">
                    <div className="flex items-center">
                        <span className="material-icons text-4xl">Collaborators</span>
                        <div className="ml-4">
                            <div className="text-2xl font-bold">{collaboratorCount}</div>
                            <p>Collaborators en mission!</p>
                        </div>
                    </div>
                    <a className="block mt-4 text-black-200 hover:text-blue-100" href="/users/collaborator">
                        <span>Plus de détails</span>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Cards;
