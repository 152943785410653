import React, { useState, useEffect } from 'react';
import NoteInvoiceService from '../../services/NoteInvoiceService';
const pdfMake = require('pdfmake/build/pdfmake');
const pdfFonts = require('pdfmake/build/vfs_fonts');

// Assign the vfs to pdfMake
pdfMake.vfs = pdfFonts;

const SupplierNoteInvoicePage = () => {
  const [invoices, setInvoices] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [email, setEmail] = useState('');

    useEffect(() => {
      const storedUser = localStorage.getItem('user');
      if (storedUser) {
        const user = JSON.parse(storedUser);
        if (user.email) {
          setEmail(user.email); 
        } else {
          console.error("User data missing email");
        }
      } else {
        console.error("No user data found in localStorage");
      } 
      }, []);

  useEffect(() => {
    fetchInvoices(email);
  }, [email]);

  const fetchInvoices = async (email) => {
    try {
      const data = await NoteInvoiceService.getsupplierInvoicesList(email);
      setInvoices(data);
      console.log('data', data);
    } catch (error) {
      console.error('Failed to load Invoices:', error);
    }
  };

  const handleFilterChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredInvoices = invoices.filter(invoice =>
    Object.values(invoice).some(val =>
      String(val).toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

      const generatePdf = (invoice) => {
        try {
          const pageWidth = 595.28;
          const pageMargins = [40, 60, 40, 60]; // left, top, right, bottom
    
          const docDefinition = {
            pageMargins: pageMargins,
            content: [
              // Header
              {
                columns: [
                  { text: `Issue au ${invoice.date_emission}`, alignment: 'left', fontSize: 10 }
                ],
                margin: [0, 0, 0, 20]
              },
              // Title
              {
                canvas: [
                  { type: 'line', x1: 0, y1: 0, x2: pageWidth - 80, y2: 0, lineWidth: 1 }
                ]
              },
              { text: 'Facture', style: 'header', alignment: 'center', margin: [0, 10] },
              {
                canvas: [
                  { type: 'line', x1: 0, y1: 0, x2: pageWidth - 80, y2: 0, lineWidth: 1 }
                ]
              },
              { text: '', margin: [0, 10] },
              // Invoice details
              {
                table: {
                  headerRows: 1,
                  widths: ['30%', '70%'],
                  body: [
                    [{text: 'Nom Complet', bold: true },  {text: `${invoice.nom} ${invoice.prenom}` }],
                    [{ text: 'Facture ID', bold: true }, { text: invoice.id }],
                    [{ text: 'Note de frais ID', bold: true }, { text: invoice.noteDeFraisId }],
                    [{ text: 'Mission', bold: true }, { text: invoice.mission }],
                    [{ text: 'Déplacement', bold: true }, { text: invoice.deplacement }],
                    [{ text: 'Repas', bold: true }, { text: invoice.repas }],
                    [{ text: 'Séjour', bold: true }, { text: invoice.sejour }],
                    [{ text: 'Autres', bold: true }, { text: invoice.autres }],
                  ]
                },
                layout: {
                  hLineWidth: (i, node) => (i === 0 || i === node.table.body.length ? 2 : 1),
                  vLineWidth: () => 0,
                  hLineColor: (i, node) => (i === 0 || i === node.table.body.length ? '#AAAAAA' : '#CCCCCC'),
                  paddingLeft: () => 8,
                  paddingRight: () => 8,
                  paddingTop: () => 8,
                  paddingBottom: () => 8
                }
              },
              // Total
              {
                columns: [
                  { width: '*', text: '' },
                  { 
                    width: 'auto', 
                    text: `Total: ${invoice.total} Dh`,
                    bold: true,
                    alignment: 'right',
                    margin: [0, 20, 0, 0]
                  }
                ]
              }
            ],
            styles: {
              header: {
                fontSize: 22,
                bold: true,
                margin: [0, 0, 0, 10]
              }
            },
          };
    
          pdfMake.createPdf(docDefinition).open();
          console.log('PDF generation attempted');
        } catch (error) {
          console.error('Error generating PDF:', error);
        }
      };

      function showMessage(messageContent) {
        const messageElement = document.createElement('div');
        messageElement.className = 'fixed top-0 left-1/2 transform -translate-x-1/2 bg-gray-500 text-white p-4 rounded z-50'; 
        messageElement.innerText = messageContent;
        document.body.appendChild(messageElement);
        
        // Optionnel : supprimer le message après un certain temps
        setTimeout(() => {
            document.body.removeChild(messageElement);
        }, 3000);
    }
  const removeInvoice = async (invoiceId) => {
    try {
      const deletedInvoice = await NoteInvoiceService.deleteInvoice(invoiceId);
      console.log("Invoice deleted successfully", deletedInvoice);
      const updatedInvoices = invoices.filter(invoice => invoice.invoiceId !== invoiceId);
      setInvoices(updatedInvoices);
      showMessage('Facture supprimée avec succès');
    } catch (error) {
      console.error("Error deleting invoice:", error);
      showMessage('Une erreur est survenue lors de la suppression de la facture');
    }
  };

  const columns = [
    { id: 'id', label: 'Facture ID' },
    { id: 'noteDeFraisId', label: 'Note de frais ID' },
    { id: 'deplacement', label: 'Déplacement' },
    { id: 'repas', label: 'Repas' },
    { id: 'sejour', label: 'Séjour' },
    { id: 'total', label: 'Total' },
    { id: 'date_emission', label: 'Issue Date' },
    {
      id: 'action',
      label: 'Action',
      render: (invoice) => (
        <div className="flex space-x-2">
          <button className="text-blue-500 hover:text-blue-700" onClick={() => generatePdf(invoice)}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clipRule="evenodd" />
            </svg>
          </button>
          <button className="text-red-500 hover:text-red-700" onClick={() => removeInvoice(invoice.invoiceId)}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
            </svg>
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center bg-gray-100 p-4 rounded-lg">
        <h1 className="text-2xl font-bold text-gray-700">FACTURES</h1>
      </div>

      <div className="mt-4 flex justify-between items-center">
        <input
          type="text"
          placeholder="Id de la facture"
          value={searchTerm}
          onChange={handleFilterChange}
          className="border border-gray-300 p-2 rounded w-full"
        />
      </div>

      <div className="mt-4">
        <div className="overflow-auto rounded-lg shadow-lg">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                {columns.map((column) => (
                  <th key={column.id} className="px-4 py-2 bg-gray-800 text-white">
                    {column.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredInvoices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((invoice) => (
                <tr key={invoice.invoiceId} className="border-t">
                  {columns.map((column) => (
                    <td key={column.id} className="px-4 py-2">
                      {column.render ? column.render(invoice) : invoice[column.id]}
                    </td>
                  ))}
                </tr>
              ))}
              {filteredInvoices.length === 0 && (
                <tr>
                  <td colSpan={columns.length} className="text-center py-4">
                    Aucune Facture avec cet ID "{searchTerm}"
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between mt-4">
          <div>
            <label>Rows per page: </label>
            <select
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
              className="border border-gray-300 rounded p-1"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={25}>25</option>
            </select>
          </div>
          <div>
            <button
              onClick={() => handleChangePage(page - 1)}
              disabled={page === 0}
              className={`px-4 py-2 border rounded ${page === 0 ? 'text-gray-500' : 'text-blue-500'}`}
            >
              Previous
            </button>
            <button
              onClick={() => handleChangePage(page + 1)}
              disabled={(page + 1) * rowsPerPage >= filteredInvoices.length}
              className={`px-4 py-2 border rounded ${((page + 1) * rowsPerPage >= filteredInvoices.length) ? 'text-gray-500' : 'text-blue-500'}`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierNoteInvoicePage;                                                     