import React, { useEffect, useState } from 'react';
import SupplierAdminService from '../../../services/SupplierAdminService';
import axios from 'axios';

const SupplierAdminAddEditForm = ({superAdmin, onClose, editMode }) => {
  const [formData, setFormData] = useState({
    supplierAdminPrenom: superAdmin?.utilisateur?.prenom || '',
    supplierAdminNom: superAdmin?.utilisateur?.nom || '',
    supplierAdminEmail: superAdmin?.utilisateur?.email || '',
    supplierAdminNumTel: superAdmin?.utilisateur?.numTel || '',
    supplierAdminMotDePasse: superAdmin?.utilisateur?.prenom || '',
    companyId: superAdmin?.company?.id || ''  
  });
  const [, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get('http://localhost:8087/companys/allcompanys');
        setCompanies(response.data || []);
      } catch (error) {
        setError('Could not load companies.');
      }
    };

    fetchCompanies();
  }, []);

  useEffect(() => {
    if (superAdmin) {
      setFormData({
        supplierAdminPrenom: superAdmin.utilisateur.prenom,
        supplierAdminNom: superAdmin.utilisateur.nom,
        supplierAdminEmail: superAdmin.utilisateur.email,
        supplierAdminNumTel: superAdmin.utilisateur.numTel,
        supplierAdminMotDePasse: '', 
        companyId: superAdmin.company ? superAdmin.company.id : '',
      });
    } 
  }, [superAdmin]);


  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      if (superAdmin) {
        await SupplierAdminService.updateSupplierAdmin(superAdmin.id, formData);
        alert('Supplier Admin mis à jour avec succès');
      } else {
        await SupplierAdminService.addSupplierAdmin(formData);
        alert('Supplier Admin ajouté avec succès');
      }
      onClose();  
    } catch (error) {
      setError(error.response?.data?.message || 'An unexpected error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-500 bg-opacity-75">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-lg max-h-[80vh] p-8 overflow-y-auto">
        <h2 className="text-xl font-semibold mb-4">{superAdmin ? 'Modifier Supplier Admin' : 'Ajouter Supplier Admin'}</h2>
        {error && <div className="text-red-500 mb-4">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Prénom</label>
            <input
              type="text"
              name="supplierAdminPrenom"
              value={formData.supplierAdminPrenom}
              onChange={handleChange}
              required
              className="border border-gray-300 p-2 rounded w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Nom</label>
            <input
              type="text"
              name="supplierAdminNom"
              value={formData.supplierAdminNom}
              onChange={handleChange}
              required
              className="border border-gray-300 p-2 rounded w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Email</label>
            <input
              type="email"
              name="supplierAdminEmail"
              value={formData.supplierAdminEmail}
              onChange={handleChange}
              required
              className="border border-gray-300 p-2 rounded w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Téléphone</label>
            <input
              type="tel"
              name="supplierAdminNumTel"
              value={formData.supplierAdminNumTel}
              onChange={handleChange}
              required
              className="border border-gray-300 p-2 rounded w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Mot de Passe</label>
            <input
              type="password"
              name="supplierAdminMotDePasse"
              value={formData.supplierAdminMotDePasse}
              onChange={handleChange}
              required={!superAdmin}
              className="border border-gray-300 p-2 rounded w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Entreprise</label>
            <select
              name="companyId"
              value={formData.companyId}
              onChange={handleChange}
              required
              className="border border-gray-300 p-2 rounded w-full"
            >
              <option value="">Select a company</option>
              {companies.map((company) => (
                  <option key={company.id} value={company.id}>
                    {company.nom}
                  </option>
                ))}
            </select>
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={() =>onClose(false)} // Reset the form on close
              className="ml-2 border bg-gray-400 px-4 py-2 rounded hover:bg-gray-200 transition"
            >
              Annuler
            </button>
            <button
              type="submit"
              className="bg-gray-700 text-white px-4 py-2 rounded hover:bg-gray-800 transition"
            
            >
              {editMode ? 'Modifier' : 'Enregistrer'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SupplierAdminAddEditForm;