import React, { useState, useEffect, useRef } from 'react';
import CompanyService from '../../services/CompanyService';
import CompanyFormPage from '../CompanyFormPage/CompanyFormPage';


const CompanyPage = () => {
  const [companies, setCompanies] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [, setLoading] = useState(true);
  const [isAddEditVisible, setIsAddEditVisible] = useState(false);
  const [editingCompany, setEditingCompany] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const searchRef = useRef();

  useEffect(() => {
    fetchCompanies();
}, []);

const fetchCompanies = async () => {
  try {
      const token = localStorage.getItem('token');
      
      if (!token) {
          throw new Error('No token available. Please log in.');
      }

      const response = await fetch('http://localhost:8087/companys/allcompanys', {
          headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
          },
      });

      if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
      }

      const text = await response.text();
      const data = text ? JSON.parse(text) : {};
      setCompanies(data);
  } catch (error) {
      console.error("Error fetching companies:", error);
  }
};



  const loadCompanies = async () => {
    try {
      setLoading(true);
      const data = await CompanyService.getCompanyList();
      setCompanies(data);
    } catch (error) {
      console.error('Failed to load companies:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };
  function showMessage(messageContent) {
    const messageElement = document.createElement('div');
    messageElement.className = 'fixed top-0 left-1/2 transform -translate-x-1/2 bg-gray-500 text-white p-4 rounded z-50'; 
    messageElement.innerText = messageContent;
    document.body.appendChild(messageElement);
    
    // Optionnel : supprimer le message après un certain temps
    setTimeout(() => {
        document.body.removeChild(messageElement);
    }, 3000);
}

  const handleDelete = async (id) => {
    try {
      await CompanyService.deleteCompany(id);
      showMessage(`Société supprimée avec succès`);
      loadCompanies();
    } catch (error) {
      console.error('Failed to delete company:', error);
      showMessage(`Une erreur est servenue`);
    }
  };

  const handleAddEditModalOpen = (company = null) => {
    setEditingCompany(company);
    setIsAddEditVisible(true);
  };

  const handleAddEditModalClose = async (updatedCompany) => {
    if (updatedCompany) {
      await loadCompanies(); // Refresh the companies list
    }
    setIsAddEditVisible(false);
    setEditingCompany(null);
  };

  const filteredCompanies = companies.filter(
    (company) =>
      company.nom && typeof company.nom === 'string' && company.nom.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const columns = [
    { id: 'id', label: 'ID' },
    { id: 'nom', label: 'Nom de la société' },
    { id: 'adresse', label: 'Adresse' },
    { id: 'delaiReglement', label: 'Délai de Reglement', render: (record) => record.delaiReglement?.delaiEnJours || 'N/A' },
    { id: 'typeSociete', label: 'Type de société' },
    {
      id: 'action',
      label: 'Action',
      render: (record) => (
        <div className="flex space-x-2">
          {/* Edit button with SVG */}
          <button className="text-blue-500 hover:text-blue-700" onClick={() => handleAddEditModalOpen(record)}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path d="M17.414 2.586a2 2 0 010 2.828l-10 10A2 2 0 016 16H4a1 1 0 01-1-1v-2a2 2 0 01.586-1.414l10-10a2 2 0 012.828 0zM15 6L5 16H4v-1l10-10 1 1z" />
            </svg>
          </button>
          {/* Delete button with SVG */}
          <button className="text-red-500 hover:text-red-700" onClick={() => handleDelete(record.id)}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M6 8a1 1 0 011 1v7a1 1 0 001 1h4a1 1 0 001-1V9a1 1 0 112 0v7a3 3 0 01-3 3H8a3 3 0 01-3-3V9a1 1 0 011-1z"
                clipRule="evenodd"
              />
              <path
                fillRule="evenodd"
                d="M4 4a1 1 0 011-1h10a1 1 0 011 1v1H4V4zm2-2a2 2 0 00-2 2v1a1 1 0 001 1h10a1 1 0 001-1V4a2 2 0 00-2-2H6z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      ),
    },
  ];

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center bg-gray-100 p-4 rounded-lg">
        <h1 className="text-2xl font-bold text-gray-700">SOCIÉTÉS</h1>
        <button className="bg-gray-700 text-white py-2 px-4 rounded" onClick={() => handleAddEditModalOpen()}>
          Nouvelle Société
        </button>
      </div>

      <div className="mt-4 flex justify-between items-center">
        <input
          ref={searchRef}
          type="text"
          placeholder="Nom de la Société"
          value={searchTerm}
          onChange={handleSearch}
          className="border border-gray-300 p-2 rounded w-full"
        />
      </div>

      <div className="mt-4">
        <div className="overflow-auto rounded-lg shadow-lg">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                {columns.map((column) => (
                  <th key={column.id} className="px-4 py-2 bg-gray-800 text-white">
                    {column.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredCompanies.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((company) => (
                <tr key={company.id} className="border-t">
                  {columns.map((column) => (
                    <td key={column.id} className="px-4 py-2">
                      {column.render ? column.render(company) : company[column.id]}
                    </td>
                  ))}
                </tr>
              ))}
              {filteredCompanies.length === 0 && (
                <tr>
                  <td colSpan={columns.length} className="text-center py-4">
                    No Companies with this title "{searchTerm}"
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between mt-4">
          <div>
            <label>Rows per page: </label>
            <select
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
              className="border border-gray-300 rounded p-1"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={25}>25</option>
            </select>
          </div>
          <div>
            <button
              onClick={() => handleChangePage(page - 1)}
              disabled={page === 0}
              className={`px-4 py-2 border rounded ${page === 0 ? 'text-gray-500' : 'text-blue-500'}`}
            >
              Previous
            </button>
            <button
              onClick={() => handleChangePage(page + 1)}
              disabled={(page + 1) * rowsPerPage >= filteredCompanies.length}
              className={`px-4 py-2 border rounded ${((page + 1) * rowsPerPage >= filteredCompanies.length) ? 'text-gray-500' : 'text-blue-500'}`}
            >
              Next
            </button>
          </div>
        </div>
      </div>

      {isAddEditVisible && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">
              {editingCompany ? 'Modifier la Société' : 'Nouvelle Société'}
            </h2>
            <CompanyFormPage 
              company={editingCompany} 
              closeDialog={handleAddEditModalClose} 
              isEditing={!!editingCompany}
            />
            <button
              onClick={() => handleAddEditModalClose()}
              className="mt-4 bg-red-500 text-white py-2 px-4 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanyPage;
