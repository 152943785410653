// nav-data.js
import { CgProfile } from "react-icons/cg";
import { FaUsers } from "react-icons/fa6";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { FaBuildingUser } from "react-icons/fa6";
import { GoRocket } from "react-icons/go";
import { SlCalender } from "react-icons/sl";


import { CiUser } from "react-icons/ci";
import { LiaBusinessTimeSolid } from "react-icons/lia";
import { MdLockReset } from "react-icons/md";
import { FaFileInvoiceDollar, FaReceipt } from "react-icons/fa";
const iconSize = 25;
export const navbarData = [
  {
    routeLink: 'dashboard',
    icon: <MdOutlineDashboardCustomize size={iconSize} />,
    label: 'TABLEAU DE BORD',
    allowedRoles: ['ADMIN', 'MANAGER', 'COLLABORATEUR']
  },
  {
     
    icon: <FaUsers size={iconSize} />,
    label: 'USERS',
    allowedRoles: ['ADMIN', 'MANAGER'],
    items: [
      {
        routeLink: 'users/admin',
        icon: <CiUser size={iconSize} />,
        label: 'Admins',
        allowedRoles: ['ADMIN']
      },
      {
        routeLink: 'users/manager',
        icon: <CiUser size={iconSize} />,
        label: 'Managers',
        allowedRoles: ['ADMIN']
      },
      {
        routeLink: 'users/supplierAdmin',
        icon: <CiUser size={iconSize} />,
        label: 'Super-admins',
        allowedRoles: ['ADMIN', 'MANAGER']
      },
      {
        routeLink: 'users/collaborator',
        icon: <CiUser size={iconSize} />,
        label: 'Collaborateurs',
        allowedRoles: ['ADMIN', 'MANAGER']
      }
    ]
  },
  {
    routeLink: 'mission',
    icon: <GoRocket size={iconSize} />,
    label: 'MISSION',
    allowedRoles: ['ADMIN', 'MANAGER']
  },
  {
    routeLink: 'company',
    icon: <FaBuildingUser size={iconSize} />,
    label: 'SOCIETE CLIENTE',
    allowedRoles: ['ADMIN', 'MANAGER']
  },
  {
    routeLink: 'timesheet',
    icon: <SlCalender size={iconSize} />,
    label: 'FEUILLE DE TEMPS',
    allowedRoles: ['COLLABORATEUR']
  },
  {
    routeLink: 'timesheets',
    icon: <LiaBusinessTimeSolid size={iconSize} />,
    label: 'Feuilles de temps enregistrées',
    allowedRoles: ['ADMIN', 'MANAGER']
  },
  {
    routeLink: 'notes',
    icon: <LiaBusinessTimeSolid size={iconSize} />,
    label: ' Notes de frais enregistrées',
    allowedRoles: ['ADMIN', 'MANAGER']
  },
  {
    routeLink: 'invoice',
    icon: <FaFileInvoiceDollar size={iconSize} />,
    label: 'FACTURES',
    allowedRoles: ['MANAGER', 'COLLABORATEUR'],
    freelancerOnly:true
  },
  {
    routeLink: 'noteInvoice',
    icon: <FaFileInvoiceDollar size={iconSize} />,
    label: 'Notes De Frais Facture',
    allowedRoles: ['MANAGER', 'COLLABORATEUR'],
    freelancerOnly:true
  },
  {
    routeLink: 'noteFrais',
    icon: <FaReceipt size={iconSize} />,
    label: 'NOTES DE FRAIS',
    allowedRoles: ['COLLABORATEUR'],
    
  },
  {
    routeLink: 'supplierTimesheetInvoice',
    icon: <FaFileInvoiceDollar size={iconSize} />,
    label: 'Feuille de temps factures',
    allowedRoles: ['SUPPLIER_ADMIN']
  },
  {
    routeLink: 'supplierNoteInvoice',
    icon: <FaFileInvoiceDollar size={iconSize} />,
    label: 'Notes de frais factures',
    allowedRoles: ['SUPPLIER_ADMIN']
  },
  {
    routeLink: 'profile',
    icon: <CgProfile size={iconSize} />,
    label: 'PROFIL',
    allowedRoles: ['ADMIN', 'MANAGER', 'COLLABORATEUR', 'SUPPLIER_ADMIN']
  },
  {
    routeLink: 'reset-password',
    icon: <MdLockReset size={iconSize}  />,
    label: 'Réinitialiser mot de passe',
    allowedRoles: ['ADMIN', 'MANAGER', 'COLLABORATEUR', 'SUPPLIER_ADMIN']
  } 
];
