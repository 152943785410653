import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import CompanyService from '../../services/CompanyService.js';


const CompanyFormPage = ({ data, closeDialog }) => {
  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    defaultValues: {
      nom: '',
      adresse: '',
      typeSociete: '', // Ensure this is included in the default values
      nic: '',
      nrcs: '',
      siret: '',
      capitaleSocial: '',
      formeJuridique: '',
      note: '',
      villercs: '',
      compteBancaire: '',
      delaiReglement: '',
      conditionFacturation: '',
      pays: '',
      tva: '',
      deviseFacturation: '',
      managerId: ''
    }
  });
  function showMessage(messageContent) {
    console.log("Message to show:", messageContent); // Ajoutez cette ligne
    const messageElement = document.createElement('div');
    messageElement.className = 'fixed top-0 left-1/2 transform -translate-x-1/2 bg-gray-500 text-white p-4 rounded z-50'; 
    messageElement.innerText = messageContent;
    document.body.appendChild(messageElement);
    
    // Optionnel : supprimer le message après un certain temps
    setTimeout(() => {
        document.body.removeChild(messageElement);
    }, 1000);
}


  const [managers, setManagers] = useState([]);
  const [transformedCompteBancaires, setTransformedCompteBancaires] = useState([]);
  const [transformedDelaiReglement, setTransformedDelaiReglement] = useState([]);
  const [transformedConditionFacturations, setTransformedConditionFacturations] = useState([]);
  const [transformedDeviseFacturations, setTransformedDeviseFacturations] = useState([]);
  const [transformedPays, setTransformedPays] = useState([]);
  const [transformedTva, setTransformedTva] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (data) {
      Object.keys(data).forEach(key => {
        setValue(key, data[key]);
      });
    }

    const fetchData = async () => {
      setLoading(true);
      try {
        const [
          compteBancaires,
          delaiReglements,
          conditionFacturations,
          deviseFacturations,
          pays,
          tva,
          fetchedManagers
        ] = await Promise.all([
          CompanyService.getAllCompteBancaires(),
          CompanyService.getAllDelaiReglements(),
          CompanyService.getAllConditionFacturations(),
          CompanyService.getAllDeviseFacturations(),
          CompanyService.getAllPays(),
          CompanyService.getAllTVA(),
          CompanyService.getManagers()
        ]);

        setTransformedCompteBancaires(Array.isArray(compteBancaires) ? compteBancaires : []);
        setTransformedDelaiReglement(Array.isArray(delaiReglements) ? delaiReglements : []);
        setTransformedConditionFacturations(Array.isArray(conditionFacturations) ? conditionFacturations : []);
        setTransformedDeviseFacturations(Array.isArray(deviseFacturations) ? deviseFacturations : []);
        setTransformedPays(Array.isArray(pays) ? pays : []);
        setTransformedTva(Array.isArray(tva) ? tva : []);
        
        const managersData = Array.isArray(fetchedManagers)
          ? fetchedManagers.map(manager => ({ ...manager, id: parseInt(manager.id, 10) }))
          : [];
        setManagers(managersData);
      } catch (error) {
        console.error('Error fetching data:', error);
        showMessage('Failed to load data.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [data, setValue]);

  const onFormSubmit = async (formData) => {
    const typeSocieteMapping = {
        societe_cliente: 'SOCIETE_CLIENTE',
        societe_fournisseur: 'SOCIETE_FOURNISSEUR',
    };

    // Transform the data to match the backend API expectations
    const finalFormData = {
        nom: formData.nom,
        typeSociete: typeSocieteMapping[formData.typeSociete] || formData.typeSociete,
        nic: formData.nic,
        nrcs: formData.nrcs,
        siret: formData.siret,
        adresse: formData.adresse,
        capitaleSocial: formData.capitaleSocial,
        formeJuridique: formData.formeJuridique,
        note: formData.note,
        villercs: formData.villercs,
        // Send IDs instead of full objects
        compteBancaireId: formData.compteBancaire ? parseInt(formData.compteBancaire, 10) : null,
        delaiReglementId: formData.delaiReglement ? parseInt(formData.delaiReglement, 10) : null,
        conditionFacturationId: formData.conditionFacturation ? parseInt(formData.conditionFacturation, 10) : null,
        deviseFacturationId: formData.deviseFacturation ? parseInt(formData.deviseFacturation, 10) : null,
        paysId: formData.pays ? parseInt(formData.pays, 10) : null,
        tvaId: formData.tva ? parseInt(formData.tva, 10) : null,
        managerId: formData.managerId ? parseInt(formData.managerId, 10) : null
    };

    try {
        setLoading(true);
        await CompanyService.addCompany(finalFormData);
        closeDialog(true);
        showMessage('Company added successfully');
    }catch (error) {
      console.error('Error adding company:', error);
      const errorMessage = error.response?.data?.message || 'Failed to save company';
      console.log("Error message:", errorMessage); // Ajoutez cette ligne
      showMessage(errorMessage);
  }finally {
        setLoading(false);
    }
};

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-500 bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-[90vh] max-h-[80vh] p-8 overflow-y-auto">
        <h2 className="text-lg font-bold mb-6 text-center">Nouvelle Société</h2>
        <form onSubmit={handleSubmit(onFormSubmit)} className="space-y-6">
          <div className="space-y-4">
            <h4 className="text-gray-700 text-lg font-semibold mb-4">INFORMATIONS GENERALES</h4>
            
            <input className="border rounded-md p-3 w-full" placeholder="Société Name" {...register('nom', { required: 'Nom is required' })} />
            {errors.nom && <p className="text-red-600">{errors.nom.message}</p>}
            
            <input className="border rounded-md p-3 w-full" placeholder="Société adresse" {...register('adresse', { required: 'Adresse is required' })} />
            {errors.adresse && <p className="text-red-600">{errors.adresse.message}</p>}
            
            <div className="grid grid-cols-3 gap-4">
            <input className="border rounded-md p-3 w-full" placeholder="NIC" type="number" {...register('nic')} />
            <input className="border rounded-md p-3 w-full" placeholder="NRC/SIREN" type="number" {...register('nrcs')} />
            <input className="border rounded-md p-3 w-full" placeholder="SIRET" type="number" {...register('siret')} />
            </div>
            <div className="grid grid-cols-3 gap-4">
            <input className="border rounded-md p-3 w-full" placeholder="Capitale Social" type="number" {...register('capitaleSocial')} />
            <input className="border rounded-md p-3 w-full" placeholder="Forme Juridique" type="text" {...register('formeJuridique')} />
            <input className="border rounded-md p-3 w-full" placeholder="Note" type="text" {...register('note')} />
            </div>
            <input className="border rounded-md p-3 w-full" placeholder="Ville RCS" type="text" {...register('villercs')} />
            <label htmlFor="typeSociete" className="sr-only">Type de Société</label>
            <select id="typeSociete" className="border rounded-md p-3 w-full" {...register('typeSociete', { required: 'Type de société est requis' })}>
              <option value="" disabled hidden>Type de Société</option>
              <option value="societe_cliente">Société Cliente</option>
              <option value="societe_fournisseur">Société Fournisseur</option>
            </select>
            {errors.typeSociete && <p className="text-red-600">{errors.typeSociete.message}</p>}
            <div className="grid grid-cols-2 gap-4">
            <select className="border rounded-md p-3 w-full" {...register('compteBancaire')}>
              <option value="" disabled hidden>Compte Bancaire</option>
              {transformedCompteBancaires.map(option => (
                  <option key={option.id} value={option.id}>{option.nom}</option>
              ))}
            </select>
            <select className="border rounded-md p-3 w-full" {...register('delaiReglement')}>
                <option value="" disabled hidden>Délai de Règlement</option>
                {transformedDelaiReglement.map(option => (
                    <option key={option.id} value={option.id}>{option.delaiEnJours}</option>
                ))}
            </select>
            
            </div>
            <div className="grid grid-cols-2 gap-4">
            <select className="border rounded-md p-3 w-full" {...register('pays')}>
                <option value="" disabled hidden>Pays</option>
                {transformedPays.map(option => (
                    <option key={option.id} value={option.id}>{option.nom}</option>
                ))}
            </select>
            <select className="border rounded-md p-3 w-full" {...register('tva')}>
                <option value="" disabled hidden>TVA</option>
                {transformedTva.map(option => (
                    <option key={option.id} value={option.id}>{option.taux}</option>
                ))}
            </select>
            </div>
            <div className="grid grid-cols-2 gap-4">
            <select className="border rounded-md p-3 w-full" {...register('deviseFacturation')}>
                <option value="" disabled hidden>Devise de Facturation</option>
                {transformedDeviseFacturations.map(option => (
                    <option key={option.id} value={option.id}>{option.nom}</option>
                ))}
            </select>
            <select className="border rounded-md p-3 w-full" {...register('conditionFacturation')}>
                <option value="" disabled hidden>Conditions de Facturation</option>
                {transformedConditionFacturations.map(option => (
                    <option key={option.id} value={option.id}>{option.nom}</option>
                ))}
            </select>
            </div>
           
            <select className="border rounded-md p-3 w-full" {...register('managerId')}>
              <option value="" disabled hidden>Manager</option>
              {managers.map(manager => (
                <option key={manager.id} value={manager.id}>{manager.utilisateur.nom}</option>
              ))}
            </select>
          </div>

          <div className="flex justify-end">
            <button type="button" className="bg-gray-300 text-gray-700 rounded-md px-4 py-2 mr-2" onClick={() => closeDialog(false)}>
              Annuler
            </button>
            <button type="submit" className="bg-blue-500 text-white rounded-md px-4 py-2" disabled={loading}>
              {loading ? 'En cours...' : 'Enregistrer'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CompanyFormPage;
