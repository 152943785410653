import axios from 'axios';
const API_URL ='http://localhost:8077/api/timesheet';

const UploadService={

    uploadFiles: async (formData) => {
        try {
          const response = await axios.post(`${ API_URL}/upload`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) => {
              console.log(`Upload Progress: ${Math.round((progressEvent.loaded * 100) / progressEvent.total)}%`);
            },
          });
          return response.data; // Assuming the API returns the FileEntity array in the response body
        } catch (error) {
          console.error('Error uploading files:', error);
          throw new Error('Something went wrong while uploading files.');
        }
      },

};

export default UploadService;