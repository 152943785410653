import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar/Sidebar';
import Navbar from './Navbar/Navbar';

const Layout = () => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const handleSidebarCollapse = (collapsed) => {
    setSidebarCollapsed(collapsed);
  };

  return (
    <div className="layout">
      <Sidebar onCollapse={handleSidebarCollapse} /> {/* Pass onCollapse */}
      <div className={`main-content ${sidebarCollapsed ? 'collapsed' : ''}`}>
        <Navbar />
        <div className="content">
          <Outlet context={{ sidebarCollapsed }} /> {/* Pass sidebarCollapsed */}
        </div>
      </div>
    </div>
  );
};

export default Layout;
