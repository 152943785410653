import { API_BASE_URL } from '../config';

import axios from 'axios';

const API_URL = 'http://localhost:9500/api/auth'; // Ensure this is correct


const authService = {
  
  login: async (email, password) => {
    try {
      const response = await fetch(`${API_URL}/authenticate`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) throw new Error(`Login failed with status ${response.status}`);

      const data = await response.json();
    //  console.log('Login response data:', data);

      const { access_token, refresh_token, role: userRole, email: userEmail, prenom : userFirstName, nom: userLastName, numTel: userPhone} = data;
      const user = { email: userEmail, prenom : userFirstName, nom: userLastName, numTel: userPhone, role: userRole };
      localStorage.setItem('authToken', access_token);
      localStorage.setItem('refreshToken', refresh_token);
      localStorage.setItem('userRole', userRole);
      localStorage.setItem('user', JSON.stringify(user));

  /*  console.log('Stored auth token:', localStorage.getItem('authToken'));
      console.log('Stored refresh token:', localStorage.getItem('refreshToken'));
      console.log('Stored user role:', localStorage.getItem('userRole'));*/
      console.log('Stored user:', localStorage.getItem('user'));

      return data;
    } catch (error) {
      console.error('Error during login:', error);
      throw error;
    }
  },
  
  register: async (requestData) => {
    try {
      const response = await axios.post(`${API_URL}/register`, requestData);
      return response; 
    } catch (error) {
      throw error;
    }
  },
  
  getUser: () => {
    const user = localStorage.getItem('user');
 //   console.log('User retrieved:', user);
    return user ? JSON.parse(user) : null;
  },

isLoggedIn: () => {
  const authToken = localStorage.getItem('authToken');
 // console.log('Auth Token in isLoggedIn:', authToken);
  return !!authToken;
},

  getUserRole: () => {
    const role = localStorage.getItem('userRole');
    //console.log('User role from localStorage:', role);
    return role;
  },

  getUserId: () => {
    const user = JSON.parse(localStorage.getItem('user'));
    console.log("userid", user?.id);
    return user ? user.id : null;
  },


   resetPassword : async (email, currentPassword, newPassword,confirmationPassword) => {
    try {
        const response = await fetch("http://localhost:9500/api/auth/reset-password", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                currentPassword: currentPassword,
                newPassword: newPassword,
                confirmationPassword:confirmationPassword,
            }),
        });

        if (!response.ok) {
            throw new Error("Password reset failed");
        }

        const data = await response.json();
        console.log(data.message);
        return data;
    } catch (error) {
        console.error("Error during password reset:", error);
        throw error;
    }
},

  forgotPassword: async (email) => {
    try {
      const response = await fetch(`${API_BASE_URL.passwordService}/forgotpassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error('Password reset request failed');
      }

      return response.json();
    } catch (error) {
      console.error('Error during forgot password request:', error);
      throw error;
    }
  },

  refreshToken: async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      throw new Error('No refresh token available');
    }

    try {
      const response = await fetch(`http://localhost:9500/api/auth/refresh-token`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${refreshToken}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Token refresh failed');
      }

      const data = await response.json();
      const { accessToken, refreshToken: newRefreshToken } = data;

      // Store the new tokens
      localStorage.setItem('authToken', accessToken);
      localStorage.setItem('refreshToken', newRefreshToken);
    } catch (error) {
      console.error('Error during token refresh:', error);
      throw error;
    }
  },
  getToken: () => {
    const authToken = localStorage.getItem('authToken');
    console.log('Retrieved token:', authToken);
    return authToken;
  }, 

  logout: async () => {
    try {
      // Call the logout API
      await axios.post(`${API_URL}/logout`, {}, { withCredentials: true });
      // Clear local storage or perform other logout actions
      localStorage.removeItem('user'); // Example: clear user data
    } catch (error) {
      console.error('Logout failed:', error);
      throw error; // Re-throw the error to be handled in the component
    }
  },

  currentUser: null,

    setCurrentUser(user) {
        // Only add `isFreelancer` if it exists (for collaborators)
        this.currentUser = user.role === 'COLLABORATEUR' 
            ? { ...user, isFreelancer: user.isFreelancer ?? false } // Set to false if undefined
            : user;

        localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
    },

    getIsFreelancer() {
      const user = this.getCurrentUser();
      return user && user.role === 'COLLABORATEUR' ? user.isFreelancer : false;
  },

};

export default authService;