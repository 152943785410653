import axios from 'axios';

const API_URL = 'http://localhost:8089/api/v1/manager'; // Replace with your API URL

const getAuthToken = () => {
  return localStorage.getItem('authToken');
};

const ManagerService = {
  getManagers: () => axios.get(`${API_URL}/getall`, {
    headers: {
      'Authorization': `Bearer ${getAuthToken()}`,
    },
  }),
  addManager: (manager) => axios.post(`${API_URL}/add`, manager, {
    headers: {
      'Authorization': `Bearer ${getAuthToken()}`,
    },
  }),
  updateManager: (id, manager) => axios.put(`${API_URL}/update/${id}`, manager, {
    headers: {
      'Authorization': `Bearer ${getAuthToken()}`,
    },
  }),
  deleteManager: (id) => axios.delete(`${API_URL}/delete/${id}`, {
    headers: {
      'Authorization': `Bearer ${getAuthToken()}`,
    },
  }),
};

export default ManagerService;
