import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Sidebar from './components/Sidebar/Sidebar';
import Navbar from './components/Navbar/Navbar';
import { AuthProvider, useAuth } from './context/AuthContext';
import AppRoutes from './routes'; // Ensure this import path is correct
import LoginPage from './pages/LoginPage/LoginPage';
import ResetPasswordPage from './pages/ResetPasswordPage/ResetPasswordPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage/ForgotPasswordPage';
import { passwordService } from './services/passwordService';

import './index.css';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import MissionPage from './pages/MissionPage/MissionPage';
import CompanyPage from './pages/CompanyPage/CompanyPage';
import TimesheetPage from './pages/TimesheetPage/TimesheetPage';
import ManagerTimesheetPage from './pages/ManagerTimesheetPage/ManagerTimesheetPage';
import InvoicePage from './pages/InvoicePage/InvoicePage';
import CollaboratorPage from './pages/UsersPages/CollaboratorPage/CollaboratorPage';
import AdminPage from './pages/UsersPages/AdminPage/AdminPage';
import ManagerPage from './pages/UsersPages/ManagerPage/ManagerPage';
import SupplierAdminPage from './pages/UsersPages/SupplierAdminPage/SupplierAdminPage';
import DashboardPage from './pages/DashboardPage/DashboardPage';
import NoteFraisPage from './pages/NoteFraisPage/NoteFraisPage';
import ManagerNotesPage from './pages/ManagerNotesPage/ManagerNotesPage';
import NoteInvoicePage from './pages/NoteInvoicePage/NoteInvoicePage';
import SupplierNoteInvoicePage from './pages/SupplierNoteInvoicesPage/SupplierNoteInvoicePage';
import SupplierTimesheetInvoicePage from './pages/SupplierTimesheetsInvoicePage/SupplierTimesheetInvoicePage';
import RegisterPage from './pages/RegisterPage/RegisterPage';

const AppContent = () => {
  const { isAuthenticated } = useAuth(); // Use the custom hook to get authentication state
  const location = useLocation();
  
  // Determine if the current path is a path where the sidebar and navbar should be shown
  const shouldShowSidebarAndNavbar = isAuthenticated && !['/forgot-password', '/login', '/reset-password','/register'].includes(location.pathname);

  // State for sidebar collapse
  const [collapsed, setCollapsed] = useState(false);

  // Function to toggle sidebar
  const toggleSidebar = () => {
    setCollapsed((prev) => !prev);
  };

  return (
    <div className="flex flex-col min-h-screen">
      {shouldShowSidebarAndNavbar && <Navbar toggleSidebar={toggleSidebar} />}
      <div className="flex flex-1">
        {shouldShowSidebarAndNavbar && <Sidebar onCollapse={setCollapsed} collapsed={collapsed} />}
        <div className="flex-grow p-4">
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/dashboard" element={<DashboardPage />} /> 
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/mission" element={<MissionPage />} />
            <Route path="/company" element={<CompanyPage />} />
            <Route path="/timesheet" element={<TimesheetPage />} />
            <Route path="/timesheets" element={<ManagerTimesheetPage />} />
            <Route path='/invoice' element={<InvoicePage />} />
            <Route path='/noteInvoice' element={<NoteInvoicePage />} />
            <Route path="/users/admin" element={<AdminPage />}/>
            <Route path="/users/manager" element={<ManagerPage />}/>
            <Route path="/users/supplierAdmin" element={<SupplierAdminPage />} />
            <Route path="/users/collaborator" element={<CollaboratorPage />}/>
            <Route path="/noteFrais" element={<NoteFraisPage />}/>
            <Route path="/notes" element={<ManagerNotesPage />} />
            <Route path="/supplierNoteInvoice" element={<SupplierNoteInvoicePage />} />
            <Route path="/supplierTimesheetInvoice" element={<SupplierTimesheetInvoicePage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage passwordService={passwordService} />} />
            <Route path="/*" element={<AppRoutes />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

const App = () => (
  <AuthProvider>
    <Router>
      <AppContent />
    </Router>
  </AuthProvider>
);

export default App;
