import axios from 'axios';

const API_URL = 'http://localhost:8089/api/v1/collaborateur'; // Replace with your API URL

const getAuthToken = () => {
  return localStorage.getItem('authToken');
};

const CollaboratorService = {
  getCollaborators: () => axios.get(`${API_URL}/getall`, {
    headers: {
      'Authorization': `Bearer ${getAuthToken()}`,
    },
  }),
  addCollaborator: (collaborator) => axios.post(`${API_URL}/add`, collaborator, {
    headers: {
      'Authorization': `Bearer ${getAuthToken()}`,
    },
  }),
  updateCollaborator: (id, collaborator) => axios.put(`${API_URL}/update/${id}`, collaborator, {
    headers: {
      'Authorization': `Bearer ${getAuthToken()}`,
    },
  }),
  deleteCollaborator: (id) => axios.delete(`${API_URL}/delete/${id}`, {
    headers: {
      'Authorization': `Bearer ${getAuthToken()}`,
    },
  }),


  getCollaboratorByUserId : async (id) => {
    try {
        const response = await axios.get(`${API_URL}/collaborator/utilisateurId/${id}`);
        return response.data; // Assuming the API returns the collaborator object
    } catch (error) {
        throw new Error('Failed to fetch collaborator data: ' + error.message);
    }
  }

};

export const getCollaboratorCount = () => {
  return axios.get(`${API_URL}/count`, {
    headers: {
      'Authorization': `Bearer ${getAuthToken()}`,
    },
  });
};

export default CollaboratorService;
