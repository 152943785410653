//CompanyService

import axios from 'axios';
const API_URL ='http://localhost:8087/companys';

const CompanyService = {

  ////Company services  
 addCompany : async (data) => {
    try {
      const response = await axios.post(`${API_URL}/create`, data);
      return response.data;
    } catch (error) {
      console.error('Error adding company:', error.response ? error.response.data : error);
      throw error;
    }
  },
  
/*  updateCompany : async (id, data) => {
    try {
      const response = await axios.put(`${API_URL}/update/${id}`, data);
      return response.data;
    } catch (error) {
      console.error('Error updating company:', error);
      throw error;
    }
  }, */
  updateCompany: async (companyData) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`/update/${companyData.id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(companyData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error updating company:', error);
      throw error;
    }
  },


 getCompanyList : async () => {
    try {
      const response = await axios.get(`${API_URL}/allcompanys`);
      return response.data;
    } catch (error) {
      console.error('Error fetching company list:', error);
      throw error;
    }
  },
  
 deleteCompany : async (id) => {
    try {
      await axios.delete(`${API_URL}/${id}`);
    } catch (error) {
      console.error('Error deleting company:', error);
      throw error;
    }
},

  ////CompteBancaire services

  getAllCompteBancaires: async function() {
    try {
          const response = await axios.get(`${API_URL}/compteBancaire`);
          return response.data;
      }  catch (error) {
        if (error.response) {
          // Server responded with a status other than 2xx
          console.error('Error response:', error.response.data);
        } else if (error.request) {
          // Request was made, but no response was received
          console.error('No response received:', error.request);
        } else {
          // Something happened in setting up the request
          console.error('Error in setting up request:', error.message);
        }
      }
  },

  getCompteBancaire: async function(id) {
    try {
          const response = await axios.get(`${API_URL}/compteBancaire/${id}`);
          return response.data;
      } catch (error) {
          console.error("There was an error!", error);
          throw error;
      }
  },

  ////CondtionFacturation service
  getAllConditionFacturations: async function() {
    try {
          const response = await axios.get(`${API_URL}/conditionFacturation`);
          return response.data;
      } catch (error) {
        if (error.response) {
          // Server responded with a status other than 2xx
          console.error('Error response:', error.response.data);
        } else if (error.request) {
          // Request was made, but no response was received
          console.error('No response received:', error.request);
        } else {
          // Something happened in setting up the request
          console.error('Error in setting up request:', error.message);
        }
      }
  },

  getCondtionFacturation: async function(id) {
    try {
          const response = await axios.get(`${API_URL}/conditionFacturation/${id}`);
          return response.data;
      } catch (error) {
          console.error("There was an error!", error);
          throw error;
      }
  },

  ////delaiReglement services

  getAllDelaiReglements: async function() {
    try {
          const response = await axios.get(`${API_URL}/delaiReglement`);
          return response.data;
      } catch (error) {
        if (error.response) {
          // Server responded with a status other than 2xx
          console.error('Error response:', error.response.data);
        } else if (error.request) {
          // Request was made, but no response was received
          console.error('No response received:', error.request);
        } else {
          // Something happened in setting up the request
          console.error('Error in setting up request:', error.message);
        }
      }
  },

  getDelaiReglement: async function(id) {
    try {
          const response = await axios.get(`${API_URL}/delaiReglement/${id}`);
          return response.data;
      } catch (error) {
          console.error("There was an error!", error);
          throw error;
      }
  },

  ////DeviseFacturation service 

  getAllDeviseFacturations: async function() {
    try {
          const response = await axios.get(`${API_URL}/deviseFacturation`);
          return response.data;
      } catch (error) {
        if (error.response) {
          // Server responded with a status other than 2xx
          console.error('Error response:', error.response.data);
        } else if (error.request) {
          // Request was made, but no response was received
          console.error('No response received:', error.request);
        } else {
          // Something happened in setting up the request
          console.error('Error in setting up request:', error.message);
        }
      }
    },

  getDeviseFacturation: async function(id) {
    try {
          const response = await axios.get(`${API_URL}/deviseFacturation/${id}`);
          return response.data;
      } catch (error) {
          console.error("There was an error!", error);
          throw error;
      }
  },

  ////pays services

  getAllPays: async function() {
    try {
          const response = await axios.get(`${API_URL}/pays`);
          return response.data;
      } catch (error) {
        if (error.response) {
          // Server responded with a status other than 2xx
          console.error('Error response:', error.response.data);
        } else if (error.request) {
          // Request was made, but no response was received
          console.error('No response received:', error.request);
        } else {
          // Something happened in setting up the request
          console.error('Error in setting up request:', error.message);
        }
      }
  },

  getPays: async function(id) {
    try {
          const response = await axios.get(`${API_URL}/pays/${id}`);
          return response.data;
      } catch (error) {
          console.error("There was an error!", error);
          throw error;
      }
  },
getManagers: async function(id) {
  try {
        const response = await axios.get(`http://localhost:8089/api/v1/manager/getall`);
        return response.data;
    } catch (error) {
        console.error("There was an error!", error);
        throw error;
    }
},

  ////TVA services

  getAllTVA: async function() {
    try {
          const response = await axios.get(`${API_URL}/tva`);
          return response.data;
      }  catch (error) {
        if (error.response) {
          // Server responded with a status other than 2xx
          console.error('Error response:', error.response.data);
        } else if (error.request) {
          // Request was made, but no response was received
          console.error('No response received:', error.request);
        } else {
          // Something happened in setting up the request
          console.error('Error in setting up request:', error.message);
        }
      }
  },

  getTVA: async function(id) {
    try {
          const response = await axios.get(`${API_URL}/tva/${id}`);
          return response.data;
      } catch (error) {
          console.error("There was an error!", error);
          throw error;
      }
  },
 

};


export default CompanyService;
