import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AuthProvider } from './context/AuthContext'; // Adjust the path if needed

 
import './index.css';

// Define your theme
/*const theme = createTheme({
  palette: {
    mode: 'dark', // Correctly define the mode here
    primary: {
      main: '#1976d2', // Example color
    },
    secondary: {
      main: '#dc004e',
    },
    background: {
      default: '#303030', // Background for dark mode
    },
  },
  // Additional theme customization...
});*/

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>
);