// src/services/MissionService.js
import axios from 'axios';

const API_URL = 'http://localhost:8095/missions';

const getAuthConfig = () => {
    const token = localStorage.getItem('authToken');
    console.log('Auth Token:', token);
    return {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    };
};

const MissionService = {
    // Fetch mission count
    getMissionCount: async () => {
        try {
            const response = await axios.get(`${API_URL}/count`, getAuthConfig());
            return response.data;
        } catch (error) {
            console.error('Error fetching mission count:', error);
            throw error;
        }
    },

    // Fetch list of missions
    getMissionList: async () => {
      try {
          const config = getAuthConfig();
          console.log('Request config:', config); // Debug log
          const response = await axios.get(`${API_URL}/listmissions`, config);
          return response.data;
      } catch (error) {
          console.error('Error details:', error.response || error); // Enhanced error logging
          throw error;
      }
  },

    // Delete a mission
    deleteMission: (id) => axios.delete(`${API_URL}/delete/${id}`, getAuthConfig()),

    // Update a mission
    updateMission: (id, missionData) => axios.put(
        `${API_URL}/update/${id}`, 
        missionData, 
        getAuthConfig()
    ),

    // Create a new mission
    createMission: async (missionData) => {
      try {
        const response = await axios.post(`${API_URL}/create`, missionData);
           return response.data;
    } catch (error) {
      console.error('Error adding mission:', error.response ? error.response.data : error);
      throw error;
    }
  },


    // Get a specific mission by ID
    getMission: (id) => axios.get(`${API_URL}/get/${id}`, getAuthConfig())
        .then(res => res.data),
};

export default MissionService;