import axios from 'axios';
const API_URL ='http://localhost:8077/api/timesheet';

const TimesheetService = {

    // Get all timesheets
  getTimesheets: async () => {
    try {
      const response = await axios.get(`${API_URL}/all`);
      return response.data;
    } catch (error) {
      console.error("Error fetching timesheets", error);
      throw error;
    }
  },

  //Get pending timesheets
  getPendingTimesheets: async (email) => {
    try {
      const response = await axios.get(`${API_URL}/pending/${email}`);
      console.log("email", email);
      console.log('response', response);
      return response.data;
    } catch (error) {
      console.error("Error fetching pending timesheets", error);
      throw error;
    }
  },


  // Get submitted timesheets
  getSubmittedTimesheets: async ( email) => {
    try {
      const response = await axios.get(`${API_URL}/submitted`, {
        params: { email: email }
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching submitted timesheets", error);
      throw error;
    }
  },

  // Get approved timesheets
  getApprovedTimesheets: async () => {
    try {
      const response = await axios.get(`${API_URL}/approved`);
      return response.data;
    } catch (error) {
      console.error("Error fetching approved timesheets", error);
      throw error;
    }
  },

  // Get details of a specific timesheet
  getTimesheetDetails: async (timesheetId) => {
    try {
      const response = await axios.get(`${API_URL}/${timesheetId}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching details for timesheet ${timesheetId}`, error);
      throw error;
    }
  },

  // Save a timesheet
  saveTimesheet: async (timesheet) => {
    try {
      const response = await axios.post(`${API_URL}/save`, timesheet);
      return response.data;
    } catch (error) {
      console.error("Error saving timesheet", error);
      throw error;
    }
  },

  // Create a new timesheet
  createTimesheet: async (timesheet) => {
    try {
      const response = await axios.post(`${API_URL}/create`, timesheet);
      return response.data;
    } catch (error) {
      console.error("Error creating timesheet", error);
      throw error;
    }
  },

  // Submit a timesheet for approval
  sendTimesheet: async (id) => {
    try {
      const response = await axios.put(`${API_URL}/${id}/submit`, {});
      return response.data;
    } catch (error) {
      console.error(`Error submitting timesheet ${id}`, error);
      throw error;
    }
  },

  // Delete a timesheet
  deleteTimesheet: async (timesheetId) => {
    try {
      const response = await axios.delete(`${API_URL}/delete/${timesheetId}`);
      return response.data;
    } catch (error) {
      console.error(`Error deleting timesheet ${timesheetId}`, error);
      throw error;
    }
  },

  // Approve a timesheet
  approveTimesheet: async (timesheet) => {
    try {
      const response = await axios.post(`${API_URL}/approveTimesheet/${timesheet.timesheetId}`, timesheet);
      return response.data;
    } catch (error) {
      console.error(`Error approving timesheet ${timesheet.timesheetId}`, error);
      throw error;
    }
  },

  // Reject a timesheet
  rejectTimesheet: async (timesheetId, comment) => {
    try {
      const response = await axios.post(`${API_URL}/rejectTimesheet/${timesheetId}?comment=${encodeURIComponent(comment)}`);
      return response.data;
    } catch (error) {
      console.error(`Error rejecting timesheet ${timesheetId}`, error);
      throw error;
    }
  },

  // Update a timesheet
  updateTimesheet: async (timesheetId, timesheet) => {
    try {
      const response = await axios.put(`${API_URL}/update/${timesheetId}`, timesheet);
      return response.data;
    } catch (error) {
      console.error(`Error updating timesheet ${timesheetId}`, error);
      throw error;
    }
  }
};
export default TimesheetService;