import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import CollaboratorAddEditForm from './CollaboratorAddEditForm';

const CollaboratorPage = () => {
  const [collaborators, setCollaborators] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false); // Control for modal visibility
  const [editMode, setEditMode] = useState(false);
  const [currentCollaborator, setCurrentCollaborator] = useState({});
  const [filter, setFilter] = useState('');
  const [, setFilteredManagers] = useState([]);
  const searchRef = useRef();

  useEffect(() => {
    fetchCollaborators();
    fetchManagers();
  }, []);

  const fetchCollaborators = async () => {
    setLoading(true);
    try {
      const response = await axios.get('http://localhost:8089/api/v1/collaborateur/getall');
      setCollaborators(response.data);
    } catch (error) {
      console.error('Error fetching collaborators:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchManagers = async () => {
    try {
      const response = await axios.get("http://localhost:8089/api/v1/manager/getall");
      setFilteredManagers(response.data);
    } catch (error) {
      console.error("Error fetching managers:", error);
    }
  };

  const handleOpen = () => {
    setCurrentCollaborator({});
    setEditMode(false);
    setOpen(true); // Open the modal when adding a new collaborator
  };

  const handleClose = (refresh = false) => {
    setOpen(false); // Close the modal
    if (refresh) {
      fetchCollaborators(); // Refresh the list after closing if needed
    }
  };

  const handleEdit = (collaborator) => {
    setCurrentCollaborator(collaborator);
    setEditMode(true);
    setOpen(true); // Open the modal in edit mode
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`http://localhost:8089/api/v1/collaborateur/delete/${id}`);
      fetchCollaborators();
    } catch (error) {
      console.error('Error deleting collaborator:', error);
    }
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredCollaborators = collaborators.filter(
    (collaborator) =>
      (collaborator.utilisateur.prenom || '').toLowerCase().includes(filter.toLowerCase()) ||
      (collaborator.utilisateur.nom || '').toLowerCase().includes(filter.toLowerCase()) ||
      (collaborator.utilisateur.email || '').toLowerCase().includes(filter.toLowerCase())
  );
  

  return (
    <div className="flex flex-col p-5">
      {loading ? (
        <div className="flex justify-center items-center h-full">
          <span className="loader"></span> {/* Add your loading spinner here */}
        </div>
      ) : (
        <>
          <div className="flex justify-between items-center bg-gray-100 p-4 rounded-lg">
            <h2 className="text-2xl font-bold text-gray-700">Collaborateurs</h2>
            <button
              onClick={handleOpen}
              className="bg-gray-700 text-white p-2 rounded hover:bg-blue-600"
            >
              Nouveau collaborateur
            </button>
          </div>
          <div className="mb-5 flex items-center">
            <input
              ref={searchRef}
              placeholder="Rechercher par nom"
              className="border border-gray-300 p-2 rounded w-full"
              onChange={handleFilterChange}
            />
          </div>
          <div className="overflow-y-auto max-h-[calc(100vh-100px)]">
            <table className="min-w-full border-collapse border border-gray-200">
              <thead>
                <tr className="bg-gray-100">
                  <th className="border border-gray-300 p-2">ID</th>
                  <th className="border border-gray-300 p-2">Prénom</th>
                  <th className="border border-gray-300 p-2">Nom</th>
                  <th className="border border-gray-300 p-2">Email</th>
                  <th className="border border-gray-300 p-2">Téléphone</th>
                  <th className="border border-gray-300 p-2">Freelancer</th>
                  <th className="border border-gray-300 p-2">Manager</th>
                  <th className="border border-gray-300 p-2">Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredCollaborators.map((collaborator) => (
                  <tr key={collaborator.id} className="border-b hover:bg-gray-300">
                    <td className=" border border-gray-300 p-2">{collaborator.id}</td>  
                    <td className=" border border-gray-300 p-2">{collaborator.utilisateur.prenom}</td>   
                    <td className=" border border-gray-300 p-2">{collaborator.utilisateur.nom}</td> 
                    <td className=" border border-gray-300 p-2">{collaborator.utilisateur.email}</td>  
                    <td className=" border border-gray-300 p-2">{collaborator.utilisateur.numTel}</td>  
                    <td className=" border border-gray-300 p-2">{collaborator.freelancer ? 'Oui' : 'Non'}</td>
                    <td className=" border border-gray-300 p-2">{collaborator.manager ? collaborator.manager.utilisateur.nom : "N/A"}</td>  
                    <td className="p-2 flex space-x-2">
                    
                      <button 
                        className="text-blue-500 hover:text-blue-700" 
                         aria-label="Modifier"
                        onClick={() => handleEdit(collaborator)}
                      >
                        {/* Edit SVG */}
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536M9 11l6-6 3.536 3.536-6 6H9v-3.536z" />
                        </svg>
                      </button>
                      <button 
                        className="text-red-500 hover:text-red-700" 
                        aria-label="Supprimer"
                        onClick={() => handleDelete(collaborator.id)}
                      >
                        {/* Delete SVG */}
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {open && (
            <CollaboratorAddEditForm 
              collaborator={currentCollaborator}
              onClose={handleClose}
              editMode={editMode}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CollaboratorPage;