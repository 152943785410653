import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import AdminAddEditForm from "./AdminEditForm";

const AdminPage = () => {
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentAdmin, setCurrentAdmin] = useState({});
  const [filter, setFilter] = useState("");
  const searchRef = useRef();

  useEffect(() => {
    fetchAdmins();
    console.log("Admin data received for editing:", currentAdmin); // Log currentAdmin
  }, [currentAdmin]); // Dependencies include currentAdmin

  const fetchAdmins = async () => {
    setLoading(true);
    try {
      const response = await axios.get("http://localhost:8089/api/v1/admin/getall");
      if (response && response.data) {
        setAdmins(response.data);
    } else {
        console.error("La réponse de l'API ne contient pas de données.");
    }
    } catch (error) {
      console.error("Error fetching admins:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = () => {
    setCurrentAdmin({});
    setEditMode(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async (id) => {
    console.log("Deleting admin with ID:", id);
    try {
      await axios.delete(`http://localhost:8089/api/v1/admin/delete/${id}`);
      fetchAdmins();
    } catch (error) {
      console.error("Error deleting admin:", error);
    }
  };

  const handleEdit = (admin) => {
    setCurrentAdmin(admin);
    setEditMode(true);
    setOpen(true);
    console.log("Editing admin:", admin);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredAdmins = admins.filter(
    (admin) =>
      (admin.utilisateur.prenom || '').toLowerCase().includes(filter.toLowerCase()) ||
      (admin.utilisateur.nom || '').toLowerCase().includes(filter.toLowerCase()) ||
      (admin.utilisateur.email || '').toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div className="flex flex-col p-5">
      {loading ? (
        <div className="flex justify-center items-center h-full">
          <span className="loader"></span>
        </div>
      ) : (
        <>
          <div className="flex justify-between items-center bg-gray-100 p-4 rounded-lg">
            <h2 className="text-2xl font-bold text-gray-700">ADMINS</h2>
            <button
              onClick={handleOpen}
              className="bg-gray-700 text-white p-2 rounded hover:bg-blue-600"
            >
              Nouveau Admin
            </button>
          </div>
          <div className="mb-5 flex items-center">
            <input
              ref={searchRef}
              placeholder="Rechercher par nom"
              className="border border-gray-300 p-2 rounded w-full"
              onChange={handleFilterChange}
            />
          </div>
          <div className="overflow-y-auto max-h-[calc(100vh-100px)]">
            <table className="min-w-full border-collapse">
              <thead>
                <tr className="bg-gray-200">
                  <th className="border-b p-2">ID</th>
                  <th className="border-b p-2">Prénom</th>
                  <th className="border-b p-2">Nom</th>
                  <th className="border-b p-2">Email</th>
                  <th className="border-b p-2">Téléphone</th>
                  <th className="border-b p-2">Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredAdmins.length === 0 ? (
                  <tr>
                    <td colSpan="6" className="text-center py-4">No admins found</td>
                  </tr>
                ) : (
                  filteredAdmins.map((admin) => (
                    <tr key={admin.id} className="border-b hover:bg-gray-100">
                      <td className="p-2">{admin.id}</td>
                      <td className="p-2">{admin.utilisateur.prenom}</td>
                      <td className="p-2">{admin.utilisateur.nom}</td>
                      <td className="p-2">{admin.utilisateur.email}</td>
                      <td className="p-2">{admin.utilisateur.numTel}</td>
                      <td className="p-2 flex space-x-2">
                        <button
                          onClick={() => handleEdit(admin)}
                          className="text-blue-500 hover:bg-blue-100 p-2 rounded"
                          aria-label="Modifier"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.828 4.586l4.586 4.586-1.414 1.414-4.586-4.586 1.414-1.414zM3 21v-3.586l10-10 3.586 3.586-10 10H3z" />
                          </svg>
                        </button>
                        <button
                          onClick={() => handleDelete(admin.id)}
                          className="text-red-500 hover:bg-red-100 p-2 rounded"
                          aria-label="Supprimer"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>

          <AdminAddEditForm
            admin={editMode ? currentAdmin : null}
            visible={open}
            onClose={handleClose}
          />
        </>
      )}
    </div>
  );
};

export default AdminPage;
