import React, { useState, useEffect } from 'react';

import TimesheetService from '../../services/TimesheetService';

function ManagerTimesheetPage() {
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showComment, setShowComment] = useState({});
  const [timesheets, setTimesheets] = useState([]);
  const [filteredData, setFilteredData] = useState(timesheets);
  const [email, setEmail] = useState('');
  const [comments, setComments] = useState({});

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    const storedUser = localStorage.getItem('user');

    if (storedUser) {
      const user = JSON.parse(storedUser);
      if (user.email) {
        setEmail(user.email);
      } else {
        console.error("User data missing email");
      }
    } else {
      console.error("No user data found in localStorage");
    }
  }, [email]);

  const fetchSubmittedTimesheets = async (email) => {
    try {
      const data = await TimesheetService.getSubmittedTimesheets(email);
      console.log("data",data);
      const sortedData = data.sort((a, b) => {
        const statusOrder = {
          SUBMITTED: 1,
          APPROVED: 2,
          REJECTED: 3,
        };
        return statusOrder[a.status] - statusOrder[b.status];
      });
      setTimesheets(sortedData);
      setFilteredData(sortedData);
    } catch (error) {
      console.error('Error fetching submitted timesheets:', error);
    }
  };

  useEffect(() => {
    fetchSubmittedTimesheets(email);
  }, [email]);

  const handleSearch = (event) => {
    const value = event.target.value.trim().toLowerCase();
    setSearchTerm(value);
    
    setFilteredData(
      timesheets.filter((row) => {
        const timesheetId = row.timesheetId ? row.timesheetId.toString().toLowerCase() : "";
        const collaborateurLastName = row.collaborateurLastName ? row.collaborateurLastName.toString().toLowerCase() : "";

        return (
          timesheetId.includes(value) || collaborateurLastName.includes(value)
        );
      })
    );
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  function showMessage(messageContent) {
    const messageElement = document.createElement('div');
    messageElement.className = 'fixed top-0 left-1/2 transform -translate-x-1/2 bg-gray-500 text-white p-4 rounded z-50'; 
    messageElement.innerText = messageContent;
    document.body.appendChild(messageElement);
    setTimeout(() => {
        document.body.removeChild(messageElement);
    }, 3000);
}

  const onValide = async (row) => {
    try {
      await TimesheetService.approveTimesheet(row);
      row.status = "APPROVED";
      showMessage(`Feuille de temp ${row.timesheetId} est approuvée.`);
    } catch (error) {
      console.error(`Error approving timesheet ${row.timesheetId}:`, error);
      showMessage(`Erreur lors de l'approbation de la feuille de temps ${row.timesheetId}.`);
    }
  };

  const onReject = async (row, comment) => {
    if (row.timesheetId) { // Ensure timesheetId is defined
      try {
        await TimesheetService.rejectTimesheet(row.timesheetId, comment);
        row.status = "REJECTED"; // Update the row status in local state
        showMessage(`Feuille de temps ${row.timesheetId} est refusée.`);
      } catch (error) {
        console.error(`Error rejecting timesheet ${row.timesheetId}:`, error);
        showMessage(`Erreur lors du rejet de la feuille de temps ${row.timesheetId}.`);
      }
    } else {
      console.error('Timesheet ID is undefined or null.');
      showMessage('Erreur: ID de la feuille de temps non défini.');
    }
  };

  const onSubmitComment = async (row) => {
    const comment = comments[row.timesheetId]; // Get the comment from state
    if (comment) {
      await onReject(row, comment); // Pass the comment to the reject function
      setShowComment((prev) => ({ ...prev, [row.timesheetId]: false }));
      setComments((prev) => ({ ...prev, [row.timesheetId]: '' })); // Clear the comment after submission
    }
  };

  const toggleCommentField = (row) => {
    setShowComment((prev) => ({ ...prev, [row.timesheetId]: !prev[row.timesheetId] }));
  };

  
  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center bg-gray-100 p-4 rounded-lg">
        <h1 className="text-2xl font-bold text-gray-700">FEUILLES DE TEMPS</h1>
      </div>

      <div className="mt-4 flex justify-between items-center">
        <input
          type="text"
          placeholder="Timesheet Id/ Nom du Collaborateur"
          value={searchTerm}
          onChange={handleSearch}
          className="border border-gray-300 p-2 rounded w-full"
        />
      </div>

      <div className="mt-4">
        <div className="overflow-auto rounded-lg shadow-lg">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="px-4 py-2 bg-gray-800 text-white">Nom du Collaborateur</th>
                <th className="px-4 py-2 bg-gray-800 text-white">Feuille de temps Id</th>
                <th className="px-4 py-2 bg-gray-800 text-white">Mission</th>
                <th className="px-4 py-2 bg-gray-800 text-white">Société</th>
                <th className="px-4 py-2 bg-gray-800 text-white">Date</th>
                <th className="px-4 py-2 bg-gray-800 text-white">Jours travaillés</th>
                <th className="px-4 py-2 bg-gray-800 text-white">Commentaires</th>
                <th className="px-4 py-2 bg-gray-800 text-white">Partie du jours</th>
                <th className="px-4 py-2 bg-gray-800 text-white">Fichiers</th>
                <th className="px-4 py-2 bg-gray-800 text-white">Action</th>
              </tr>
            </thead>
            <tbody>
              {timesheets.length > 0 ? (
                filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                  <tr key={index} className="border-t">
                    <td className="px-4 py-2">{row.collaborateurLastName} {row.collaborateurFirstName}</td>
                    <td className="px-4 py-2">{row.timesheetId}</td>
                    <td className="px-4 py-2">{row.missionName}</td>
                    <td className="px-4 py-2">{row.societeNom}</td>
                    <td className="px-4 py-2">{formatDate(row.date)}</td>
                    <td className="px-4 py-2">{row.jours_travaille}</td>
                    <td className="px-4 py-2">
                      <ul>
                        {Array.isArray(row.comments) && row.comments.length > 0 ? (
                          row.comments.map((c, idx) => (
                            <li key={idx}>
                              <strong>day {c.jour}:</strong> {c.text}
                            </li>
                          ))
                        ) : (
                          <li>No comments</li>
                        )}
                      </ul>
                    </td>
                    <td className="px-4 py-2">
                      <ul>
                        {Array.isArray(row.selectedPeriods) && row.selectedPeriods.length > 0 ? (
                          row.selectedPeriods.map((selectedPeriod, idx) => (
                            <li key={idx}>
                              <strong>day {selectedPeriod.jour}:</strong> {selectedPeriod.partie_selectionné}
                            </li>
                          ))
                        ) : (
                          <li>No selected period</li>
                        )}
                      </ul>
                    </td>
                    <td className="px-4 py-2">
                      <ul className="list-disc pl-5">
                        {row.filesnames.map((filename, index) => (
                          <li key={index} className="mb-2">
                            <div
                              onClick={() => window.open(`http://localhost:8077/api/timesheet/files/${filename}`, '_blank')}
                              className="cursor-pointer text-blue-600 hover:text-blue-800 font-semibold no-underline hover:underline"
                            >
                              {filename}
                            </div>
                          </li>
                        ))}
                      </ul>
                    </td>
                    <td className="px-4 py-2">
                      {row.status === "APPROVED" ? (
                        <span>Approuvée</span>
                      ) : row.status === "REJECTED" ? (
                        <span>Refusée</span>
                      ) : (
                        <>
                          <button className="text-green-500 hover:text-green-700 mr-2" onClick={() => onValide(row)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                            </svg>
                          </button>
                          <button className="text-red-500 hover:text-red-700" onClick={() => toggleCommentField(row)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                            </svg>
                          </button>
                          {showComment[row.timesheetId] && (
                            <div className="mt-2">
                              <input
                                type="text"
                                placeholder="Comment"
                                value={comments[row.timesheetId] || ''}
                                onChange={(e) => setComments((prev) => ({ ...prev, [row.timesheetId]: e.target.value }))}
                                className="border border-gray-300 p-2 rounded w-full mb-2"
                              />
                              <button
                                onClick={() => onSubmitComment(row)}
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                              >
                                Submit
                              </button>
                            </div>
                          )}
                        </>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={10} className="text-center py-4">No timesheets available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between mt-4">
          <div>
            <label>Rows per page: </label>
            <select
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
              className="border border-gray-300 rounded p-1"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={25}>25</option>
            </select>
          </div>
          <div>
            <button
              onClick={() => handlePageChange(null, page - 1)}
              disabled={page === 0}
              className={`px-4 py-2 border rounded ${page === 0 ? 'text-gray-500' : 'text-blue-500'}`}
            >
              Previous
            </button>
            <button
              onClick={() => handlePageChange(null, page + 1)}
              disabled={(page + 1) * rowsPerPage >= filteredData.length}
              className={`px-4 py-2 border rounded ${((page + 1) * rowsPerPage >= filteredData.length) ? 'text-gray-500' : 'text-blue-500'}`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManagerTimesheetPage;