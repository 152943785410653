import React, { useState, useEffect } from 'react';
import CompanyService from '../../services/CompanyService.js';
import MissionService from '../../services/MissionService.js';
import TimesheetService from '../../services/TimesheetService.js';
import UploadService from '../../services/UploadService.js';


const TimesheetPage = () =>{
      const [showSuccessButton, setShowSuccessButton] = useState(false);
      const [currentDate] = useState(new Date());
      const [isModalOpen, setIsModalOpen] = useState(false);
      const [, setHasPendingTimesheets] = useState(true);
      const [selectedTimesheetId, setSelectedTimesheetId] = useState(null);
      const [showTimesheet] = useState(true);
      const [showHead, setShowHead] = useState(true);
      const [year] = useState(new Date().getFullYear());
      const [month] = useState(new Date().getMonth() + 1);
      const [numberOfDays, setNumberOfDays] = useState(new Date(year, month, 0).getDate());
      const [daysOfMonth, setDaysOfMonth] = useState([]);
      const [selectedFiles, setSelectedFiles] = useState([]);
      const [, setLoading] = useState(false);
      const [, setError] = useState(null);
      const [email, setEmail] = useState('');
      const [contracts, setContracts] = useState([]);
      const [missions, setMissions] = useState([]);
 
      useEffect(() => {
       
        const fetchContracts = async () => {
          try {
            const response = await CompanyService.getCompanyList();
            setContracts(response);
          } catch (error) {
            console.error('Error fetching company list:', error);
            showMessage(`Error fetching company list`);
          }
        };
    
        // Fetch mission list
        const fetchMissions = async () => {
          try {
            const response = await MissionService.getMissionList();
            setMissions(response);
          } catch (error) {
            console.error('Error fetching mission list:', error);
            showMessage(`Error fetching mission list`);
          }
        };
    
        fetchContracts();
        fetchMissions();
      }, []);
      
      function showMessage(messageContent) {
        const messageElement = document.createElement('div');
        messageElement.className = 'fixed top-0 left-1/2 transform -translate-x-1/2 bg-gray-500 text-white p-4 rounded z-50'; 
        messageElement.innerText = messageContent;
        document.body.appendChild(messageElement);
        
        // Optionnel : supprimer le message après un certain temps
        setTimeout(() => {
            document.body.removeChild(messageElement);
        }, 3000);
    }

      const initializeDayValues = () => {
        const valeur_jour = {};
        daysOfMonth.forEach(day => {
          valeur_jour[day] = null;
        });

        return valeur_jour;
      };

      const [formState, setFormState] = useState({
        collaborateur:{
          email:''
        },
        day:'',
        missionName: '',
        societeNom: '',
        jours_travaille: 0,
        status: 'NOTSAVED',
        files:[],
        date: new Date(),
        valeur_jour: initializeDayValues(),
        comments: [],
        selectedPeriods: [],
        daysOfMonth :  Array.from({ length: numberOfDays }, (_, index) => index + 1),
        selectedDay: null, 
        showCommentDiv: false, 
        showMomentDiv: false
      });

      const generateDefaultTimesheet = (email) => { 
        
        return {
          timesheetId: -1,
          collaborateur: { 
            email: email 
          },
          day: '',
          missionName: '',
          societeNom: '',
          jours_travaille: 0,
          status: 'NOTSAVED',
          /*manager: {
            id: formState.manager.id
          },*/
          files:[],
          date: new Date(),
          valeur_jour: initializeDayValues(),
          comments: [],
          selectedPeriods: [],
          daysOfMonth : Array.from({ length: numberOfDays }, (_, index) => index + 1),
          selectedDay: null, 
          showCommentDiv: false, 
          showMomentDiv: false
        };

        
      };


      const [timesheets, setTimesheets] = useState([generateDefaultTimesheet('')]);

      useEffect(() => {
      
        const storedUser = localStorage.getItem('user');
        
        if (storedUser) {
          const user = JSON.parse(storedUser);
          
       
          if (user.email) {
            const email = `${user.email}`;
            setEmail(email);
            
            setFormState((prevState) => ({
              ...prevState,
              email: email
            }));
          
          } else {
            console.error("User data missing firstName or lastName");
          }
        } else {
          console.error("No user data found in localStorage");
        }
      }, []);

      useEffect(() => {
        const fetchTimesheets = async () => {
            try {
                setLoading(true);
                setError(null);
                await loadTimesheets(email);
            } catch (err) {
                setError('Failed to load timesheets. Please try again.');
                console.error('Failed to load timesheets:', err);
            } finally {
                setLoading(false);
            }
        };
    
        fetchTimesheets();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email]);
    

    const loadTimesheets = async (email) => {
      console.log('email', email);
      try {
          const data = await TimesheetService.getPendingTimesheets(email);
          
          if (data && data.length > 0) {
              const enhancedTimesheets = data.map(timesheet => ({
                  ...timesheet,
                  daysOfMonth: Array.from({ length: numberOfDays }, (_, index) => index + 1),
                  selectedDay: null,
                  showCommentDiv: false,
                  showMomentDiv: false
              }));
              const filesFromTimesheets = enhancedTimesheets.flatMap(timesheet => timesheet.files || []);
              const uniqueFiles = Array.from(new Set(filesFromTimesheets.map(file => file.nom))) // Ensure unique file names
                .map(nom => filesFromTimesheets.find(file => file.nom === nom)); // Get the first occurrence of each unique file
        
              setSelectedFiles(prevFiles => [...uniqueFiles, ...prevFiles]);
              console.log("files selected", selectedFiles) 
              setTimesheets(enhancedTimesheets);
          } else {
              const defaultTimesheet = generateDefaultTimesheet(email);
              setTimesheets([defaultTimesheet]);
          }
      } catch (error) {
          console.error('Failed to load timesheets:', error);
      }
  };

  
      useEffect(() => {
        const daysInMonth = new Date(year, month, 0).getDate();
        setNumberOfDays(daysInMonth);
        setDaysOfMonth(Array.from({ length: daysInMonth}, (_, index) => index + 1));
      }, [year, month]);

      useEffect(() => {
      }, [timesheets]);

      useEffect(() => {
        const updatedTimesheets = timesheets.map(timesheet => {
          const initialValues = daysOfMonth.reduce((acc, day) => {
            acc[day] = null; 
            return acc;
          }, {});
          return { ...timesheet, valeur_jour: initialValues };
        });
        setTimesheets(updatedTimesheets);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [daysOfMonth]);

      const isWeekend = (day) => { 
        const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
        const dayOfWeek = newDate.getDay();
        return dayOfWeek === 0 || dayOfWeek === 6;
       }; 
      const getDayName = (day) => {  
        const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
        const fullDayName = newDate.toLocaleDateString('fr-FR', { weekday: 'long' });
        return fullDayName.charAt(0).toUpperCase() + fullDayName.charAt(1).toLowerCase();
      }; 
      const resetShowSuccessButton = () => { 
        setShowSuccessButton(false);
       };  

       const handleInputChange = (day,event, timesheetId) => {
        setSelectedTimesheetId(timesheetId);
        const { value } = event.target;
        setTimesheets(prevTimesheets =>
          prevTimesheets.map(timesheet =>
            timesheet.timesheetId === timesheetId
              ? {
                  ...timesheet, valeur_jour: {...timesheet.valeur_jour,[`day${day}`]: value}

                }
              : timesheet
          )
        );
        resetShowSuccessButton();
       };

      const fillAlldays = (timesheetId) => { 
        console.log("timesheet id", timesheetId);
        setSelectedTimesheetId(timesheetId);
        //console.log("selected timesheet", selectedTimesheetId);
        setTimesheets(prevTimesheets =>
          prevTimesheets.map(timesheet => {
            if (timesheet.timesheetId === timesheetId) {
              // Create a new dayValues object with all the days set to '1' except weekends
              const updatedDayValues = daysOfMonth.reduce((acc, day) => {
                if (!isWeekend(day)) {
                  acc[`day${day}`] = '1';
                }
                return acc;
              }, {});
              
              // Return the updated timesheet
              return {
                ...timesheet,
                valeur_jour: {
                  ...timesheet.valeur_jour,
                  ...updatedDayValues
                }
               
              };
           
            }
           
            return timesheet;

          })
        );
       };  


      const clearAllCells = (timesheetId) => { 
        setSelectedTimesheetId(timesheetId);
        setTimesheets(prevTimesheets => 
          prevTimesheets.map((timesheet) => {
            if(timesheet.timesheetId === timesheetId){
              const clearedDayValues = { ...timesheet.valeur_jour };
              const clearedComments = { ...timesheet.comments };
              const clearedSelectedPeriods = { ...timesheet.selectedPeriods };

              daysOfMonth.forEach(day => {
                clearedDayValues[`day${day}`] = null;
                clearedComments[day] = writeComment(day, '', timesheetId);
                clearedSelectedPeriods[day] = onSelectionChange('Journée', day, timesheetId);
              });

              
              
              return {
                ...timesheet,
                valeur_jour: clearedDayValues,
                comments: clearedComments,
                selectedPeriods: clearedSelectedPeriods,
                selectedDay: null, 
                showCommentDiv: false, 
                showMomentDiv: false,  
              };
            }
            return timesheet;
          })
        );
       }; 

      const deleteActivities = async (timesheetId) => {  
        try {
        const deletedTimesheet = await TimesheetService.deleteTimesheet(timesheetId);
        
          console.log("Timesheet deleted successfully", deletedTimesheet);
    
          // Update the timesheets array
          const updatedTimesheets = timesheets.filter(timesheet => timesheet.timesheetId !== timesheetId);
          setTimesheets(updatedTimesheets);
    
          console.log("Updated timesheets array:", updatedTimesheets);
          console.log("Length of timesheets array:", updatedTimesheets.length);
    
          if (updatedTimesheets.length === 0) {
            console.log("No timesheets left");
           
           
            setTimesheets([ generateDefaultTimesheet('')]);
            setShowHead(true);
            //setShowTimesheet(false);
            console.log(showHead);
          } else {
            setShowHead(false);
          }
          showMessage(`feuille de temps ${timesheetId} est supprimée`);
        }
        catch(error) {
          console.error("Error deleting timesheet:", error);
          showMessage(`Erreur lors de la suppression de la feuille de temps ${timesheetId} `);
          
        }
      }; 

      const toggleCommentDiv = (day, timesheetId) => { 
        setTimesheets(prevTimesheets =>
            prevTimesheets.map(timesheet => {
                if (timesheet.timesheetId === timesheetId) {
                    const commentDivAlreadyShown = timesheet.showCommentDiv;
                    return {
                        ...timesheet,
                        selectedDay: day,
                        showCommentDiv: !commentDivAlreadyShown || timesheet.selectedDay !== day,
                        showMomentDiv: false, // Close the moment div if it's open
                    };
                }
                return timesheet;
            })
        );
    }; 

    const toggleMomentDiv = (day, timesheetId) => { 
      setTimesheets(prevTimesheets =>
          prevTimesheets.map(timesheet => {
              if (timesheet.timesheetId === timesheetId) {
                  const momentDivAlreadyShown = timesheet.showMomentDiv;
                  return {
                      ...timesheet,
                      selectedDay: day,
                      showMomentDiv: !momentDivAlreadyShown || timesheet.selectedDay !== day,
                      showCommentDiv: false, // Close the comment div if it's open
                  };
              }
              return timesheet;
          })
      );
  };
       
      const writeComment = (jour, text, timesheetId) => {  
        setTimesheets((prevTimesheets) => 
        prevTimesheets.map((timesheet) => 
          timesheet.timesheetId === timesheetId
          ? {
              ...timesheet,
              comments: Array.isArray(timesheet.comments) 
              ? timesheet.comments.some(comment => comment.jour === jour)
                ? timesheet.comments.map(c =>
                    c.jour === jour ? { ...c, text } : c
                  )
                : [...timesheet.comments, { jour, text }]
                : [{ jour, text }],
            }
          : timesheet
        )
      );
      };  
      const onSelectionChange = (partie_selectionné, jour, timesheetId) => { 
        setTimesheets((prevTimesheets) => 
          prevTimesheets.map((timesheet) => 
       timesheet.timesheetId === timesheetId 
        ?{
        ...timesheet,
        selectedPeriods: Array.isArray(timesheet.selectedPeriods)
        ? timesheet.selectedPeriods.some(period => period.jour === jour)
        ? timesheet.selectedPeriods.map(period => 
          period.jour === jour ? { ...period, partie_selectionné}: period
        )
        : [...timesheet.selectedPeriods, { jour, partie_selectionné}]
        : [{jour, partie_selectionné}],
       }
       : timesheet
        )
      );
      }; 

       const handleSaveTimesheet = async () => {
        try {
          for (const timesheet of timesheets) {
            const timesheetForm = { ...timesheet };
            Object.assign(timesheetForm, timesheet);
            const dateValue = timesheet.date;
      
          if(timesheet.timesheetId === -1){
            timesheetForm.collaborateur.email = formState.email;
          }

            if (dateValue) {
              /*timesheet.date = new Date(dateValue);*/
              timesheetForm.date = new Date(dateValue);
            }

            
      
            // Handle file upload if files are selected
            if (selectedFiles && selectedFiles.length > 0) {
              const formData = new FormData();
              selectedFiles.forEach(file => formData.append('files', file));
      
              try {
                const files = await UploadService.uploadFiles(formData);
                //timesheet.files = files;
                timesheetForm.files = files;
                console.log("timesheet files", timesheetForm.files);
                console.log("timesheet.files.length", timesheetForm.files.length);
              } catch (error) {
                console.error("Error uploading files:", error);
                alert("Error uploading files for timesheet with ID: " + timesheet.timesheetId);
                continue; 
              }
            }
      
            // Check if the timesheet already exists
            if (timesheet.status !== 'NOTSAVED') {
              try {
                
                const existingTimesheet = await TimesheetService.getTimesheetDetails(timesheet.timesheetId);
                Object.assign(existingTimesheet, timesheetForm);
                console.log("existing timesheet", existingTimesheet);
                //existingTimesheet.status = 'PENDING';
                await updateTimesheet(existingTimesheet);
                setShowSuccessButton(true);
              } catch (error) {
                console.error("Error updating timesheet:", error);
              }
            } else {
              try {
                let t = timesheet ;
                Object.assign(t, timesheetForm);
                console.log("t", t);
                const data = await TimesheetService.createTimesheet(t);
           
                timesheet.timesheetId = data.timesheetId;
                timesheet.status = data.status;
                setShowSuccessButton(true);
                console.log("data", data);
                console.log("timesheets", timesheets);
              } catch (error) {
                console.log("timesheet has not been created", timesheetForm);
                console.log("timesheet has not been created", timesheet);
                console.error("Error creating timesheet:", error);
              }
            }
          }
        } catch (error) {
          console.error("An unexpected error occurred during timesheet processing:", error);
          showMessage(`Une erreur est survenue`);
        }
      };

      const updateTimesheet = async( timesheet ) => {
         try{
          console.log('Timesheet ID:', timesheet.timesheetId);
          console.log('Updating Timesheet:', timesheet);

          const updatedTimesheet = await TimesheetService.updateTimesheet(timesheet.timesheetId, timesheet);

          setShowSuccessButton(true);
          console.log("Timesheet updated successfully:", updatedTimesheet);
         } catch(err){
          alert("error updating timesheet:",timesheet.timesheetId);
          console.error("Error updating timesheet:", err);
          setShowSuccessButton(false);
         }

      };

      const onFileSelected = (event) =>{
        const files =Array.from(event.target.files);
        setSelectedFiles(prevFiles => [...prevFiles, ...files]);
        showMessage('File séléctionée avec sucées');
        console.log("Newly selected files:", files);
      console.log("Total selected files:", [...selectedFiles, ...files]);
      setShowSuccessButton(false);
      };


      const openModal = () => setIsModalOpen(true);
      const closeModal = () => setIsModalOpen(false);

      const submitSelectedTimesheet = () =>{
      
        console.log('Selected Timesheet ID', selectedTimesheetId);

        if(!selectedTimesheetId){
          console.log('No timesheet selected');
          return;
        }

        if(selectionFormIsValid()){
          console.log('Selected Timesheet:', selectedTimesheetId);
          closeModal();
         sendTimesheet(selectedTimesheetId);
        } else {
          console.log("please select a timesheet");
        }

        console.log("Timesheets array:", timesheets);
      };

      const sendTimesheet = async (timesheetId) => {
        try {     
          let timesheet = { ...formState };
      
          console.log('Timesheet before sending:', timesheet);   
          // Send the timesheet via TimesheetService
          const response = await TimesheetService.sendTimesheet(timesheetId);   
          console.log('Timesheet sent successfully!', response);
          // Update the status of the timesheet in the state
          setTimesheets((prevTimesheets) =>
            prevTimesheets.map((timesheet) => {
              if (timesheet.timesheetId === timesheetId) {
                return { ...timesheet, status: 'SUBMITTED' };
              }
              return timesheet;
            })
          );

          // Display success notification
          showMessage(`Votre feuille de temps ${timesheetId} a été soumise`);
          // Check if there are pending timesheets
          const hasPendingTimesheets = timesheets.some((timesheet) => timesheet.status === 'PENDING');
          setHasPendingTimesheets(hasPendingTimesheets);
          
        } catch (error) {
          console.error('Error sending timesheet:', error);
          showMessage(`Une erreur est survenue lors de l'envoi de la feuille de temps ${timesheetId}`);
        }
      };
  
      const selectionFormIsValid = () => {
        
        return selectedTimesheetId !== null && selectedTimesheetId !== '';
      };

      const generateUniqueTimesheetId = () => {
        const timesheetIds = timesheets.map(timesheet => timesheet.timesheetId);  // Extract the IDs
        let newId = timesheets.length;
        while (timesheetIds.includes(newId)) {
            newId++;
        }
        return newId;
    };

      

      const addNewTimesheet = () => {
        if (!formState.email) {
          console.warn("Collaborateur name is not set yet.");
          return;
        }
      const defaultTimesheetIndex = timesheets.findIndex(timesheet => timesheet.timesheetId === -1);
      if(defaultTimesheetIndex !== -1){
        const updatedTimesheets = [...timesheets];
        updatedTimesheets.splice(defaultTimesheetIndex, 1);
        setTimesheets(updatedTimesheets);
      }

      const newTimesheet= {
        timesheetId: generateUniqueTimesheetId(),
        collaborateur: { 
          email: formState.email
        },
        day:'',
        missionName: '',
        societeNom: '',
        jours_travaille: 0,
        status: 'NOTSAVED',
        /*manager: {
          id: formState.manager.id
        },*/
        files:[],
        date: new Date(),
        valeur_jour: initializeDayValues(),
        comments: [],
        selectedPeriods: [],
        daysOfMonth : Array.from({ length: numberOfDays }, (_, index) => index + 1),
        selectedDay: null,
        showCommentDiv: false,
        showMomentDiv: false,
      };

      setTimesheets([...timesheets, newTimesheet]);
      console.log("timesheet:", newTimesheet);
     
      };

      const calculateTotaleDaysWorked = (timesheetId) => {
        //setSelectedTimesheetId(timesheetId);
        const timesheet = timesheets.find(t => t.timesheetId === timesheetId);
        if(!timesheet) 
          return 0;
        return Object.values(timesheet.valeur_jour).reduce((total, value) => {
          if (['0', '0.25', '0.5', '1'].includes(value)) {
            return total + parseFloat(value);
          }
          return total;
        }, 0);
      };




      useEffect(() => {
        if (selectedTimesheetId) {
          const timesheet = timesheets.find(t => t.timesheetId === selectedTimesheetId);
          if (timesheet) {
              const total = calculateTotaleDaysWorked(selectedTimesheetId);
              setTimesheets(prevTimesheets =>
                  prevTimesheets.map(t =>
                      t.timesheetId === selectedTimesheetId
                          ? { ...t, jours_travaille: total }
                          : t
                  )
              );
          }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTimesheetId, timesheets.find(t => t.timesheetId === selectedTimesheetId)?.valeur_jour]);

  const selectContract = (event, timesheetId) => {
     const selectedContract = event.target.value;
     setTimesheets(prevTimesheets => prevTimesheets.map(timesheet => 
      timesheet.timesheetId === timesheetId 
      ? { ...timesheet, societeNom: selectedContract} : timesheet
    )
  );
  };

  const selectMission = (event, timesheetId) => {
    const selectedMission = event.target.value;
    setTimesheets(prevTimesheets => prevTimesheets.map(timesheet => 
     timesheet.timesheetId === timesheetId 
     ? { ...timesheet, missionName: selectedMission} : timesheet
   )
 );
 }
       // Récupérez le jour actuel
       const currentDay = new Date().getDate(); 
       
 const removeFile = (fileToRemove) => {
        // Remove the file from selectedFiles
  setSelectedFiles(prevFiles => {
    const updatedFiles = prevFiles.filter(file => file !== fileToRemove);
      
          // Update timesheets to remove the file from each timesheet
      setTimesheets(prevTimesheets => 
        prevTimesheets.map(timesheet => {
              // Check if the file is in the timesheet's files
          if (timesheet.files && timesheet.files.some(file => file.nom === fileToRemove.nom)) {
                // Remove the file from the timesheet's files
            return {
              ...timesheet,
                files: timesheet.files.filter(file => file.nom !== fileToRemove.nom)
              };
            }
          return timesheet; // Return unchanged timesheet if file not found
        })
      );
      
    return updatedFiles; // Return the updated selectedFiles
  });
  setShowSuccessButton(false);
 };

const FileList = ({ files }) => {
  return (
    <div className="mr-10 text-base text-black-600">
      {files.map((file, index) => (
        <div key={index} className="flex items-center gap-1">
          <button 
            onClick={() => removeFile(file)}
            className="ml-2 text-black-500 hover:text-red-500"
            title="Remove file"
          >
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="h-4 w-4" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                strokeWidth={2} 
                d="M6 18L18 6M6 6l12 12" 
              />
            </svg>
          </button>
          <span>{file.nom ||file.name}</span>
        </div>
      ))}
    </div>
  );
};

 return (
  <div className="transition-all duration-300 ml-[2%] w-[98%] overflow-auto">
      <header className="flex justify-between items-center mb-4 p-[1px]">
  <h2 className="text-2xl font-bold">TimeSheet/Feuille de temps</h2>
  <div className="flex items-center">
    <span className="text-lg font-bold text-black bg-slate-400 rounded-lg p-2 uppercase">
      {currentDate.toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' })}
    </span>
  </div>



  <div className="flex items-center mb-4">
    <input
      id="file-upload"
      type="file"
      className="hidden"
      onChange={onFileSelected}
      accept=".pdf,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png"
      multiple
    />
    <button
      className="bg-slate-400 text-black p-2 rounded-lg mr-8"
      onClick={() => document.getElementById('file-upload').click()}
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" className="w-8 h-6" viewBox="0 0 20 20" strokeWidth="1.5" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13" />
      </svg>
    </button>
    {selectedFiles.length > 0 && <FileList files={selectedFiles} />}
    <button
      type="button"
      className="bg-gray-800 text-gray-300 p-2 rounded-lg"
      onClick={handleSaveTimesheet}
      hidden={showSuccessButton}
    >
      Enregistrer
    </button>
    <button
      className={`flex items-center ${showSuccessButton ? 'flex' : 'hidden'} bg-gray-800 text-gray-300 italic p-2 rounded`}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-7 mr-1.5 font-light">
        <path fillRule="evenodd" d="M19.916 4.626a.75.75 0 0 1 .208 1.04l-9 13.5a.75.75 0 0 1-1.154.114l-6-6a.75.75 0 0 1 1.06-1.06l5.353 5.353 8.493-12.74a.75.75 0 0 1 1.04-.207Z" clipRule="evenodd" />
      </svg>
      <span>Enregistrée</span>
    </button>
  </div>
</header>
<div className="flex flex-col items-start mb-4">
  <div className="flex items-center">
    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20">
      <path d="M0 0l20 10L0 20V0zm0 8v4l10-2L0 8z" />
    </svg>
    <button
      type="button"
      className="bg-gray-800 text-gray-300 ml-2 p-2 rounded"
      onClick={openModal}
    >
      Soumettre Pour Validation
    </button>
  </div>

  {isModalOpen && (
    <div className="fixed inset-0 z-10 w-full h-full overflow-auto bg-black bg-opacity-40">
      <div className="bg-[#c5c5c5] mx-auto my-[15%] p-[20px] border border-[#888] w-[30%] rounded-[10px]">
        <span className="float-right text-2xl font-bold cursor-pointer" onClick={closeModal}>
          &times;
        </span>
        <h2 className="text-xl font-bold mb-4">Choisir une Timesheet</h2>
        {showSuccessButton && (
          <form>
            <select
              className="rounded-[15px] w-full max-w-[200px]"
              value={selectedTimesheetId}
              onChange={(e) => setSelectedTimesheetId(e.target.value)}
            >
              <option value="">Sélectionner une timesheet</option>
              {timesheets
                .filter(timesheet => timesheet.status === "PENDING")
                .map(timesheet => (
                  <option key={timesheet.timesheetId} value={timesheet.timesheetId}>
                    Timesheet {timesheet.timesheetId}
                  </option>
                ))}
            </select>
          </form>
        )}
        <button
          className="bg-gray-800 text-gray-300 mt-4 p-2 rounded w-full"
          onClick={submitSelectedTimesheet}
        >
          Soumettre
        </button>
      </div>
    </div>
  )}
</div>
<table className="min-w-full">
    <tbody>
        <tr>
            <td className="border-t-2 border-b-2 border-r-2 border-l-2 border-[#c5c5c5] text-center align-middle bg-[#FBFBFB] w-1/5">
                <button className="w-full bg-gray-500 h-12 flex items-center justify-center text-white">Activités</button>
            </td>
            <td className="border-t-2 border-b-2 border-r-2 border-l-2 border-[#c5c5c5] text-center align-middle bg-[#FBFBFB] w-4/5">
                <button className="w-full bg-gray-500 h-12 flex items-center justify-center text-white">Heures en plus & Astreintes/Tickets</button>
            </td>
        </tr>
        </tbody>
</table>
<br />
<table className="min-w-full">
    <tbody>
        {timesheets.length > 0 && timesheets.map((timesheet, index) => (
            <React.Fragment key={index}>
                <tr>
                <td className={`border-t-2 border-b-2 border-r-2 border-l-2 border-[#c5c5c5] text-center align-middle bg-[#FBFBFB] ${!showTimesheet ? 'align-top' : 'align-middle'}`}>
                    <table className={`w-full ${showTimesheet ? 'h-20' : ''}`}>
                            <tbody>
                            <tr>
    <td colSpan="2" className="border-t-2 border-b-2 border-r-2 border-l-2 border-[#c5c5c5] text-center align-middle bg-[#FBFBFB] w-1/5">
        <table className="w-full h-full">
            {(showHead || timesheet === timesheets[0]) && (
                <thead className="h-12">
                <tr>
                    <th className="text-center text-2xl font-bold text-gray-800" colSpan="2">
                        <div className="flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-9 h-9">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Z" />
                            </svg>
                            <p className="text-xl ml-1">Activités réalisées</p>
                            <button
                                className="ml-6 w-13 h-12 bg-gray-300 rounded-full flex items-center justify-center"
                                type="button"
                                title="Ajouter une nouvelle ligne d'activité"
                                onClick={() => {
                                    addNewTimesheet(timesheet);
                                    calculateTotaleDaysWorked();
                                }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-16 h-6" viewBox="0 0 20 20">
                                    <path d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                                </svg>
                            </button>
                        </div>
                    </th>
                </tr>
            </thead>
            )}
            {showTimesheet && (
               <tbody className={`${!showTimesheet ? 'hidden' : ''}`}>
               <tr>
                   <td className="border-t-2 border-l-2 border-[#c5c5c5] text-center align-middle bg-[#FBFBFB]">
                       <label className="p-[10px_15px] block text-left">
                           <span className="text-gray-700">Société</span>
                           <select className="rounded-[15px] w-full max-w-[200px]" 
                           value={timesheet.societeNom || ""}
                               onChange={(e) => {
                                   selectContract(e, timesheet.timesheetId);
                                   resetShowSuccessButton();
                               }}>
                               <option value="" selected>-- Choisir une société --</option>
                               {contracts.map((contract, index) => (
                                   <option key={index} value={contract.nom}>{contract.nom}</option>
                               ))}
                           </select>
                       </label>
                       <label className="p-[10px_15px] block text-left">
                           <span className="text-gray-700">Mission/Project</span>
                           <select className="rounded-[15px] w-full max-w-[200px]"  
                           value={timesheet.missionName || ""} 
                               onChange={(e) => {
                                   selectMission(e, timesheet.timesheetId);
                                   resetShowSuccessButton();
                               }}>
                               <option value="" selected>-- Choisir une mission --</option>
                               {missions.map((mission, index) => (
                                   <option key={index} value={mission.titre}>{mission.titre}</option>
                               ))}
                           </select>
                       </label>
                   </td>
                   <td className="border-t-2 border-b-2 border-r-2 border-[#c5c5c5] text-center align-middle bg-[#FBFBFB]">
    <div className="flex flex-col items-center justify-center space-y-2 h-full">
        <button
            className="p-2 rounded-full hover:text-gray-600"
            type="button"
            title="Compléter le mois avec cette activité"
            onClick={() => {
                fillAlldays(timesheet.timesheetId);
                calculateTotaleDaysWorked(timesheet.timesheetId);
                resetShowSuccessButton();
            }}
        >
            <svg xmlns="http://www.w3.org/2000/svg" className="w-24 h-8 fill-current" viewBox="0 0 24 24">
                <path fill="currentColor" d="M19 19H5V8h14m0-5h-1V1h-2v2H8V1H6v2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2m-2.47 8.06L15.47 10l-4.88 4.88l-2.12-2.12l-1.06 1.06L10.59 17z"/>
            </svg>
        </button>
        <button
            className="p-2 rounded-full hover:text-gray-700"
            type="button"
            title="Vider le temps passé sur cette activité"
            onClick={() => {
                clearAllCells(timesheet.timesheetId);
                calculateTotaleDaysWorked(timesheet.timesheetId);
                resetShowSuccessButton();
            }}
        >
            <svg xmlns="http://www.w3.org/2000/svg" className="w-16 h-6 fill-current" viewBox="0 0 16 16">
                <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2M6.854 8.146 8 9.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 10l1.147 1.146a.5.5 0 0 1-.708.708L8 10.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 10 6.146 8.854a.5.5 0 1 1 .708-.708"/>
            </svg>
         </button>
         <button
            className="p-2 rounded-full hover:text-gray-700"
            type="button"
            title="Supprimer cette activité et les temps saisis"
            onClick={() => {
                deleteActivities(timesheet.timesheetId);
                resetShowSuccessButton();
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="w-16 h-6 fill-current" viewBox="0 0 16 16">
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
            </svg>
          </button>
          </div>
               </td>
               </tr>
           </tbody>
            )}
            {showTimesheet && (
               <tfoot className={`${!showTimesheet ? 'hidden' : ''}`}>
               <tr className="bg-gray-200">
                   <td className="border-t-2 border-b-2 border-r-2 border-l-2 border-[#c5c5c5] text-center align-middle bg-[#FBFBFB]" colSpan="2">
                       <p className="text-gray-800 text-lg mb-10">
                           Total de l'activité en jours:
                           <span className="inline-block ml-4">
                               <input
                                   type="text"
                                   value={timesheet.jours_travaille || 0}
                                   className="w-8 h-6 bg-transparent text-center font-bold focus:outline-none"
                                   readOnly
                               />
                           </span>
                       </p>
                   </td>
               </tr>
           </tfoot>
            )}
    </table>
  
    </td>
    <td className="border-t-2 border-b-2 border-r-2 border-l-2 border-[#c5c5c5] text-center align-middle bg-[#FBFBFB] w-4/5">
    <div className="container h-full">
        <table className="bg-gray-300 h-full w-full table-fixed">
            {(showHead || timesheet === timesheets[0]) && (
                <thead>
                <tr className="bg-gray-200">
                    {daysOfMonth.map(day => (
                        <th key={day} className={`h-4 bg-[#dddddd]`}>
                            <p className="text-sm">{getDayName(day)}</p>
                            <p className="font-bold text-center">{day}</p>
                        </th>
                    ))}
                </tr>
            </thead>
            )}
            {showTimesheet && (
                <tbody className={`text-center ${!showTimesheet ? 'hidden' : ''}`}>
                    <tr>
                        {daysOfMonth.map((day, index) => (
                            <td
                            key={index}
                            className={`
                              border-t-2 border-b-2 border-r-2 border-l-2 border-[#c5c5c5] 
                              text-center align-middle relative 
                              ${isWeekend(day) 
                                ? 'bg-[#dddddd]' 
                                : day === currentDay 
                                  ? 'bg-yellow-100' 
                                  : 'bg-[#FBFBFB]'
                              }
                            `}
                        >
                            <div className="flex flex-col items-start">
                                <div className="flex items-start gap-1">
                                    <input
                                        id={`day${day}`}
                                        name={`day${day}`}
                                        className="text-right w-8 text-gray-400 border border-gray-300"
                                        value={timesheet.valeur_jour[`day${day}`] || ''}
                                        type="text"
                                        onChange={(e) => handleInputChange(day, e, timesheet.timesheetId)}
                                    />
                                    <p className="text-xs text-gray-500 italic"></p>
                                </div>
                                <div className="flex items-start gap-1">
                                    <div className="cursor-pointer" onClick={() => toggleMomentDiv(day, timesheet.timesheetId)} title="Journée">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4">
                                            <path fillRule="evenodd" d="M1 8a7 7 0 1 1 14 0A7 7 0 0 1 1 8Zm7.75-4.25a.75.75 0 0 0-1.5 0V8c0 .414.336.75.75.75h3.25a.75.75 0 0 0 0-1.5h-2.5v-3.5Z" clipRule="evenodd" />
                                        </svg>
                                    </div>
                                    <div className="cursor-pointer" onClick={() => toggleCommentDiv(day, timesheet.timesheetId)} title="Commentez votre activité">
    <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 text-current" viewBox="0 0 16 16">
        <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
        <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9 9 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.4 10.4 0 0 1-.524 2.318l-.003.011a11 11 0 0 1-.244.637c-.079.186.074.394.273.362a22 22 0 0 0 .693-.125m.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6-3.004 6-7 6a8 8 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a11 11 0 0 0 .398-2" />
    </svg>
</div>
</div>
 
<div
  className={`
    bg-white p-2 rounded shadow-md transition-all duration-300 
    w-48 absolute z-50 bottom-1
    max-h-20 overflow-y-auto
    ${Number(day) <= 15 ? 'left-full -translate-x-4' : 'right-full translate-x-4'}
    ${timesheet.selectedDay === day && timesheet.showCommentDiv 
      ? 'opacity-100 scale-100' 
      : 'opacity-0 scale-95 pointer-events-none'
    }
  `}
>
  <p className="font-semibold text-gray-700 text-sm">Commentez votre activité :</p>
  <input
    className="
      w-full p-1 text-sm
      border border-gray-300 rounded
      focus:ring-1 focus:ring-black-500 focus:border-black-500
    "
    type="text"
    value={Array.isArray(timesheet.comments) 
      ? timesheet.comments.find(comment => comment.jour === day)?.text || '' 
      : ''
    }
    onChange={(e) => {
      writeComment(day, e.target.value, timesheet.timesheetId);
      resetShowSuccessButton();
    }}
    placeholder="Entrez votre commentaire ici"
  />
</div>

<div 
  className={`
     bg-white p-2 rounded shadow-md transition-all duration-300 
    w-48 absolute z-50 bottom-1
    max-h-20 overflow-y-auto
    ${Number(day) <= 15 ? 'left-full -translate-x-4' : 'right-full translate-x-4'}
    ${timesheet.selectedDay === day && timesheet.showMomentDiv 
      ? 'opacity-100 scale-100' 
      : 'opacity-0 scale-95 pointer-events-none'
    }
  `}
>
  <label className="block text-sm font-medium text-gray-700">
    Sélectionnez la période :
  </label>
  <select
    className="
     w-full p-1 text-sm
      border border-gray-300 rounded
      focus:ring-1 focus:ring-black-500 focus:border-black-500
    "
    value={Array.isArray(timesheet.selectedPeriods) 
      ? timesheet.selectedPeriods.find(period => period.jour === day)?.partie_selectionné || 'Journée' 
      : 'Journée'
    }
    onChange={(e) => {
      onSelectionChange(e.target.value, day, timesheet.timesheetId);
      resetShowSuccessButton();
    }}
  >
    <option value="Journée">Journée</option>
    <option value="Matin">Matin</option>
    <option value="Après-Midi">Après-Midi</option>
  </select>
</div>

                            </div>
                        </td>
                        ))}
                    </tr>
                </tbody>
            )}
            {showTimesheet && (
                <tfoot className={`${!showTimesheet ? 'hidden' : ''}`}>
                    <tr className="bg-gray-200">
                        {daysOfMonth.map((day, index) => {
                            const value = timesheet.valeur_jour[`day${day}`];
                            return (
                                <td key={index} className={`
                                  border-t-2 border-b-2 border-r-2 border-l-2 
                                  text-center align-middle bg-[#FBFBFB]
                                  ${timesheet.valeur_jour[`day${day}`] && !['0', '1', '0.5', '0.25', ''].includes(timesheet.valeur_jour[`day${day}`]) ? 'border-red-500' : ''}
                                `}>
                                    <p className="text-center text-sm">
                                        <b className="text-gray-500 font-normal">{value || ''}</b>
                                    </p>
                                </td>
                            );
                        })}
                    </tr>
                </tfoot>
            )}
        </table>
    </div>
</td>
           </tr>
           </tbody>

            </table>
          </td>
        </tr> 
        </React.Fragment>
      ))}

      </tbody>
      </table>
      </div>

);
};

export default TimesheetPage;