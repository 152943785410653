import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';

import { useParams } from 'react-router-dom';
import MissionService from '../../services/MissionService';
const MissionAddEdit = ({ onClose }) => {
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      titre: '',
      porte: '',
      honoraires: '',
      timeUnit: '',
      clientFinal: false,
      destinataireFacture: false,
      compteBancaire: '',
      delaiReglement: '',
      conditionFacturation: '',
      contratPrestation: '',
      companyId: '', // Ensure this is in defaultValues
      supplierAdminId: '', // New default value for Supplier Admin
      collaborateurId: '',
      dureeMission: '',
      dateDebut: '',
      dateFin: '',
      nonRenouvelable: false,
    },
  });

  const [compteBancaires, setCompteBancaires] = useState([]);
  const [delaiReglements, setDelaiReglements] = useState([]);
  const [conditionFacturations, setConditionFacturations] = useState([]);
  const [contratPrestations, setContratPrestations] = useState([]);
  const [entreprises, setEntreprises] = useState([]);
  const [supplierAdmins, setSupplierAdmins] = useState([]);
  const [collaborateurs, setCollaborateurs] = useState([]); 

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      axios.get(`http://localhost:8095/missions/${id}`).then((response) => {
        Object.keys(response.data).forEach((key) => {
          setValue(key, response.data[key]);
        });
      });
    }
    fetchSupplierAdmins();
    fetchCollaborateurs();
    // Fetch dropdown options
    axios.get('http://localhost:8095/missions/compteBancaire').then((response) => setCompteBancaires(response.data));
    axios.get('http://localhost:8095/missions/delaiReglement').then((response) => setDelaiReglements(response.data));
    axios.get('http://localhost:8095/missions/conditionFacturation').then((response) => setConditionFacturations(response.data));
    axios.get('http://localhost:8095/missions/contratPrestation').then((response) => setContratPrestations(response.data));

    // Fetch the list of companies
    axios.get('http://localhost:8087/companys/allcompanys')
      .then((response) => setEntreprises(response.data))
      .catch((error) => {
        console.error('Error fetching companies:', error);
        showMessage('Erreur lors de la récupération des entreprises');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, setValue]);


  function showMessage(messageContent) {
    const messageElement = document.createElement('div');
    messageElement.className = 'fixed top-0 left-1/2 transform -translate-x-1/2 bg-gray-500 text-white p-4 rounded z-50'; 
    messageElement.innerText = messageContent;
    document.body.appendChild(messageElement);
    
    // Optionnel : supprimer le message après un certain temps
    setTimeout(() => {
        document.body.removeChild(messageElement);
    }, 3000);
}

  // Fetch supplier admins based on the selected company
  const fetchSupplierAdmins = () => {
  
      axios.get(`http://localhost:8089/api/v1/supplieradmin/getall`)
        .then((response) => setSupplierAdmins(response.data))
        .catch((error) => {
          console.error('Error fetching supplier admins:', error);
          showMessage('Erreur lors de la récupération des Supplier Admins');
        });

  };
  
  const fetchCollaborateurs = () => {
    axios.get(`http://localhost:8089/api/v1/collaborateur/getall`)
      .then((response) => setCollaborateurs(response.data))
      .catch((error) => {
        console.error('Error fetching collaborateurs:', error);
        showMessage('Erreur lors de la récupération des Collaborateurs');
      });
  };
  


  const onFormSubmit = async (formData) => {
    const missionData = {
      titre: formData.titre,
      porte: formData.porte,
      honoraires: formData.honoraires,
      timeUnit: formData.timeUnit,
      clientFinal: formData.clientFinal,
      destinataireFacture: formData.destinataireFacture,
      compteBancaire: { id: formData.compteBancaire },
      delaiReglement: { id: formData.delaiReglement },
      conditionFacturation: { id: formData.conditionFacturation },
      contratPrestation: { id: formData.contratPrestation },
      companyId:  formData.companyId ? parseInt(formData.companyId, 10) : null,
      supplierAdminId:  formData.supplierAdminId ? parseInt(formData.supplierAdminId, 10) : null,
      collaborateurId:  formData.collaborateurId ? parseInt(formData.collaborateurId, 10) : null,
      dureeMission: formData.dureeMission,
      dateDebut: formData.dateDebut,
      dateFin: formData.dateFin,
      nonRenouvelable: formData.nonRenouvelable,
    };
  
    console.log('Sending mission data:', missionData);
  
    try {
      if (id) {
        await MissionService.updateMission(id, missionData);
      } else {
        await MissionService.createMission(missionData);
      }
      onClose();
      showMessage('Mission enregistrée avec succès');
    } catch (error) {
      console.error('Error:', error);
      showMessage('Erreur lors de l\'enregistrement de la mission: ' + (error.response?.data?.message || 'Erreur inconnue'));
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-500 bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-[90vh] max-h-[90vh] p-8 overflow-y-auto">
        <h2 className="text-lg font-bold mb-6 text-center">{id ? 'Modifier Mission' : 'Nouvelle Mission'}</h2>
        <form onSubmit={handleSubmit(onFormSubmit)} className="space-y-6">
          <div className="border-b border-gray-300 pb-4 mb-4">
          <h3 className="text-lg font-bold mb-4 text-blue-500">BASE</h3>
            <div className="grid gap-4">
              <input
                className="border rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Titre de la Mission"
                {...register('titre')}
              />
              <div> 
                <input
                  className="border rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Porté"
                  type='email'
                  {...register('porte')}
                />
              </div>

              <div className="grid grid-cols-3 gap-4">
                <input
                  className="border rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Honoraires"
                  type="number"
                  {...register('honoraires')}
                />
                <span className="ml-2 self-center">€ HT/</span>
                <select
                  placeholder="Jour"
                  className="border rounded-md p-3 ml-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  {...register('timeUnit')}
                >
                  <option value="" disabled hidden>
                     Jour
                    </option>
                  <option value="JOUR">Jour</option>
                  <option value="DEMI_JOURNEE">Demi Journée</option>
                  <option value="QUART_DE_JOUR">Quart de Jour</option>
                </select>
              </div>
              <div className="grid grid-cols-2 gap-4">
              <div>
              <select
                  className="border rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                  {...register('companyId')}  
                >
                  <option value="" disabled hidden> Entreprise * </option>
                  {entreprises.map(entreprise => (
                    <option key={entreprise.id} value={entreprise.id}>{entreprise.nom}</option>
                  ))}
                </select>
                </div>

                <div>
                  <input type="checkbox" {...register('clientFinal')} />
                  <label className="-mr-2">Client final</label>
                </div>
              </div>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-6 mb-5'>
                <div>
                <select
                         className="border rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                          {...register('supplierAdminId')}
                        >
                          <option value="" disabled hidden> Supplier Admin *</option>
                          {supplierAdmins.map(supplierAdmin => (
                            <option key={supplierAdmin.id} value={supplierAdmin.id}>
                              {supplierAdmin.utilisateur.nom}
                            </option>
                          )) }
                </select>
              </div>
              <div>
                  <input type="checkbox" {...register('destinataireFacture')} />
                  <label className="-mr-2">Destinataire Facture</label>
                </div>
            
              </div>
              <div>

                <select
                  className="border rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                  {...register('collaborateurId')}
                >
                  <option value="" disabled hidden> Collaborateur *</option>
                {collaborateurs.map(collaborateur => (
                      <option key={collaborateur.id} value={collaborateur.id}>
                        {collaborateur.utilisateur.nom}
                      </option>
                    ))}
            </select>
              </div>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-6 mb-5'>
              <div>
              <select
                className="border rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                {...register('compteBancaire')}
              >
                <option value="" disabled hidden>
                  Compte Bancaire *
                </option>
                {/* Replace with your actual data */}
                {compteBancaires.map(compteBancaire => (
                  <option key={compteBancaire.id} value={compteBancaire.id}>
                    {compteBancaire.nom}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <select
                className="border rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                {...register('delaiReglement')}
              >
                <option value="" disabled hidden>
                  Délai de Règlement *
                </option>
                {/* Replace with your actual data */}
                {delaiReglements.map(delaiReglement => (
                  <option key={delaiReglement.id} value={delaiReglement.id}>
                    {delaiReglement.delaiEnJours}
                  </option>
                ))}
              </select>
            </div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-6 mb-5'>
            <div>
              <select
                className="border rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                {...register('conditionFacturation')}
              >
                <option value="" disabled hidden>
                  Condition de Facturation *
                </option>
                {/* Replace with your actual data */}
                {conditionFacturations.map(conditionFacturation => (
                  <option key={conditionFacturation.id} value={conditionFacturation.id}>
                    {conditionFacturation.nom}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <select
                className="border rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                {...register('contratPrestation')}
              >
                <option value="" disabled hidden>
                  Contrat de Prestation *
                </option>
                {/* Replace with your actual data */}
                {contratPrestations.map(contratPrestation => (
                  <option key={contratPrestation.id} value={contratPrestation.id}>
                    {contratPrestation.nom}
                  </option>
                ))}
              </select>
            </div>
            </div>
              <div>
                <input
                  className="border rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Durée Initiale"
                  type="number"
                  {...register('dureeMission')}
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <input
                  className="border rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Date de Début"
                  type="date"
                  {...register('dateDebut')}
                />
                <input
                  className="border rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Date de Fin"
                  type="date"
                  {...register('dateFin')}
                />
              </div>

              <div>
                <input type="checkbox" {...register('nonRenouvelable')} />
                <label className="-mr-2">Non Renouvelable</label>
              </div>
            </div>
          </div>
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              className="bg-gray-300 rounded-md px-4 py-2"
              onClick={() => onClose(false)}
            >
              Annuler
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white rounded-md px-4 py-2"
            >
              Enregistrer
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MissionAddEdit;