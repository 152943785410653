const config = {
    development: {
      authService: 'http://localhost:9500/api/auth',
      passwordService: 'http://localhost:9500/password',
      userService: 'http://localhost:8081/api/user',
      timesheetService: 'http://localhost:8082/api/timesheet',
      // Add more services as needed
    },
    production: {
      //authService: 'https://yourdomain.com/api/auth',
     // userService: 'https://yourdomain.com/api/user',
     // timesheetService: 'https://yourdomain.com/api/timesheet',
      // Update these URLs for your production setup
    },
  };
  
  const environment = process.env.NODE_ENV || 'development';
  
  export const API_BASE_URL = config[environment];
  