// src/pages/ResetPasswordPage.js
import React, { useState } from 'react';
//import { useAuth } from '../../context/AuthContext'; // Import the auth context
//import { useNavigate } from 'react-router-dom';

const ResetPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [successMessage] = useState(null);
  const [errorMessage] = useState(null);

  //const navigate = useNavigate();
 // const { isAuthenticated } = useAuth(); // Access authentication state


  const handleSubmit = async (event) => {
    // ... existing submit logic ...
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-100 to-white">
      <div className="bg-white p-10 rounded-lg shadow-lg w-full max-w-md border border-gray-300">
        <h3 className="text-3xl font-bold text-center text-blue-600 mb-6">Modifier Mot de Passe</h3>
        <form onSubmit={handleSubmit}>
          <div className="mb-5">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
            <input
              id="email"
              className="mt-1 block w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-5">
            <label htmlFor="currentPassword" className="block text-sm font-medium text-gray-700">Mot de Passe Actuel</label>
            <input
              id="currentPassword"
              className="mt-1 block w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              required
            />
          </div>
          <div className="mb-5">
            <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">Nouveau Mot de Passe</label>
            <input
              id="newPassword"
              className="mt-1 block w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
          <div className="mb-5">
            <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">Confirmer Mot de Passe</label>
            <input
              id="confirmPassword"
              className="mt-1 block w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="w-full bg-blue-600 text-white p-3 rounded-md hover:bg-blue-700 transition duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500">Réinitialiser le Mot de Passe</button>
          <a href="/login" className="block text-center mt-4 text-blue-500 hover:underline">Annuler</a>
        </form>
        {successMessage && (
          <div className="mt-4 text-green-600">
            <p>{successMessage}</p>
          </div>
        )}
        {errorMessage && (
          <div className="mt-4 text-red-600">
            <p>{errorMessage}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPasswordPage;
