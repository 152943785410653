import axios from 'axios';
const API_URL ='http://localhost:8077/api/notes';

const NoteDeFraisService = {

    // Get all notes
  getNotesFrais: async () => {
    try {
      const response = await axios.get(`${API_URL}/all`);
      return response.data;
    } catch (error) {
      console.error("Error fetching notes", error);
      throw error;
    }
  },

  // Get submitted Notes
  getSubmittedNotes: async ( email) => {
    try {
      const response = await axios.get(`${API_URL}/submitted`, {
        params: { email: email }
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching submitted notes", error);
      throw error;
    }
  },

  // Get approved notes
  getApprovedNotes: async (email) => {
    try {
      const response = await axios.get(`${API_URL}/approved`, {
        params: { email: email }
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching approved notes", error);
      throw error;
    }
  },

  // Get details of a specific notes
  getNotesDetails: async (noteDeFraisId) => {
    try {
      const response = await axios.get(`${API_URL}/${noteDeFraisId}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching details for notes ${noteDeFraisId}`, error);
      throw error;
    }
  },

  // Save a note
  saveNote: async (note) => {
    try {
      const response = await axios.post(`${API_URL}/save`, note);
      return response.data;
    } catch (error) {
      console.error("Error saving note", error);
      throw error;
    }
  },

  // Create a new note
  createNote: async (note) => {
    try {
      const response = await axios.post(`${API_URL}/create`, note);
      return response.data;
    } catch (error) {
      console.error("Error creating note", error);
      throw error;
    }
  },

  // Delete a note
  deleteNote: async (noteDeFraisId) => {
    try {
      const response = await axios.delete(`${API_URL}/delete/${noteDeFraisId}`);
      return response.data;
    } catch (error) {
      console.error(`Error deleting note ${noteDeFraisId}`, error);
      throw error;
    }
  },

  // Approve a note
  approveNote: async (noteDeFraisId) => {
    try {
      const response = await axios.post(`${API_URL}/approveNote/${noteDeFraisId}`);
      return response.data;
    } catch (error) {
      console.error(`Error approving note ${noteDeFraisId}`, error);
      throw error;
    }
  },

  // Reject a timesheet
  rejectNote: async (noteDeFraisId, comment) => {
    try {
      const response = await axios.post(`${API_URL}/rejectNote/${noteDeFraisId}?comment=${encodeURIComponent(comment)}`);
      return response.data;
    } catch (error) {
      console.error(`Error rejecting note ${noteDeFraisId}`, error);
      throw error;
    }
  },

  // Update a note
  updateNote: async (noteDeFraisId, note) => {
    try {
      const response = await axios.put(`${API_URL}/update/${noteDeFraisId}`,note);
      return response.data;
    } catch (error) {
      console.error(`Error updating note ${noteDeFraisId}`, error);
      throw error;
    }
  }
};
export default NoteDeFraisService;