import React from 'react';
import { Navigate } from 'react-router-dom';
import authService from '../services/AuthService';
import CollaboratorService from '../services/CollaboratorService';
import  { useState, useEffect } from 'react';

const ProtectedRoute = ({ children, allowedRoles, freelancerOnly = false }) => {
    const [isFreelancer, setIsFreelancer] = useState(false);
    const isLoggedIn = authService.isLoggedIn();
    const userRole = authService.getUserRole();
    const userId = authService.getUserId();

    useEffect(() => {
        const fetchCollaborator = async () => {
            if (isLoggedIn && userRole === 'COLLABORATEUR') {
                try {
                    const data = await CollaboratorService.getCollaboratorByUserId(userId);
                    setIsFreelancer(data?.freelancer || false); // Set to true if the collaborator is a freelancer
                } catch (error) {
                    console.error(error);
                }
            }
        };

        fetchCollaborator();
    }, [isLoggedIn, userRole, userId]);

   console.log('Is Logged In:', isLoggedIn);
    console.log('User Role:', userRole);

    if (!isLoggedIn) {
        return <Navigate to="/login" />;
    }

    if (allowedRoles && !allowedRoles.includes(userRole)) {
        return <Navigate to="/unauthorized" />; // Redirect to an unauthorized page or some other route
    }

    if (freelancerOnly && !isFreelancer) {
        return <Navigate to="/unauthorized" />;
    }


    return children;
};

export default ProtectedRoute;
