import React, { useState } from 'react';

const Navbar = () => {
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        // Implement search functionality here
        alert(`Searching for: ${searchQuery}`);
    };

    return (
        <nav className="flex items-center justify-between p-4 bg-gray-800 text-white">
            <div className="logo">
                {/* Add your logo here */}
            </div>
            <div className="flex items-center">
                <div className="search-container">
                    <form onSubmit={handleSearchSubmit} className="flex">
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            placeholder="Search..."
                            className="p-2 rounded-l bg-gray-700 text-white"
                        />
                        <button type="submit" className="p-2 bg-blue-500 rounded-r">Go</button>
                    </form>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;