import React, { useEffect, useState } from 'react';
import authService from '../../services/AuthService.js';

const ProfilePage = () => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await authService.getUser();
        console.log('Fetched user:', user); // Debugging line
        setCurrentUser(user);
      } catch (error) {
        console.error('Failed to fetch user:', error);
      }
    };

    fetchUser();
  }, []);

  if (!currentUser) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-100 to-white">
        <p className="text-lg text-gray-700">Veuillez vous connecter.</p>
      </div>
    );
  }

  return (
    <div id="profile-page" className="p-5 bg-gray-50">
      <div className="mx-auto max-w-4xl">
        <div className="mb-5 rounded-lg shadow-lg">
          <div className="text-center bg-gray-800 text-white p-4 text-2xl rounded-t-lg">
            <h3>Mon Profil</h3>
          </div>
          <div className="p-6 bg-white rounded-b-lg">
            <div className="mb-5">
              <strong className="text-lg text-gray-700">Nom Complet</strong>
              <div className="p-3 bg-gray-200 rounded-lg">
                <div className="text-gray-800">{currentUser.nom} {currentUser.prenom}</div>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-5">
              <div className="px-3">
                <strong className="text-lg text-gray-700">Nom</strong>
                <div className="p-3 bg-gray-200 rounded-lg">
                  <div className="text-gray-800">{currentUser.nom}</div>
                </div>
              </div>
              <div className="px-3">
                <strong className="text-lg text-gray-700">Prénom</strong>
                <div className="p-3 bg-gray-200 rounded-lg">
                  <div className="text-gray-800">{currentUser.prenom}</div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-5">
              <div className="px-3">
                <strong className="text-lg text-gray-700">Email</strong>
                <div className="p-3 bg-gray-200 rounded-lg">
                  <div className="text-gray-800">{currentUser.email}</div>
                </div>
              </div>
              <div className="px-3">
                <strong className="text-lg text-gray-700">Téléphone</strong>
                <div className="p-3 bg-gray-200 rounded-lg">
                  <div className="text-gray-800">{currentUser.numTel}</div>
                </div>
              </div>
            </div>

            <div className="mb-5">
              <strong className="text-lg text-gray-700">Rôle</strong>
              <div className="p-3 bg-gray-200 rounded-lg">
                <div className="text-gray-800">{currentUser.role}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
