import React, { useState, useEffect } from 'react';
import { FaPaperclip, FaTimes } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import NoteDeFraisService from '../../services/NotesDeFraisService';
import UploadService from '../../services/UploadService.js';
import MissionService from '../../services/MissionService.js';



const NoteDeFraisForm = ({ data, closeDialog }) => {
  const [email, setEmail] = useState('');
  useEffect(() => {
      
    const storedUser = localStorage.getItem('user');
    
    if (storedUser) {
      const user = JSON.parse(storedUser);
      
   
      if (user.email) {
        const email = `${user.email}`;
        setEmail(email);
    
      
      } else {
        console.error("User data missing firstName or lastName");
      }
    } else {
      console.error("No user data found in localStorage");
    }
  }, []);

  function showMessage(messageContent) {
    const messageElement = document.createElement('div');
    messageElement.className = 'fixed top-0 left-1/2 transform -translate-x-1/2 bg-gray-500 text-white p-4 rounded z-50'; 
    messageElement.innerText = messageContent;
    document.body.appendChild(messageElement);
    
    // Optionnel : supprimer le message après un certain temps
    setTimeout(() => {
        document.body.removeChild(messageElement);
    }, 3000);
}

  const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm({
    defaultValues: {
      collaborateur: { email: email },
      NoteDeFraisId: '',
      date: '',
      mission: '',
      description: '',
      sejour: '',
      repas: '',
      autres: '',
      justificatif: '',
      deplacement: '',
      status:'NOTSAVED'
    }
  });

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [missions, setMissions] = useState([]);


  // Fetch the mission list when the component is mounted
  useEffect(() => {
    const fetchMissions = async () => {
      try {
        const response = await MissionService.getMissionList();
        setMissions(response);
      } catch (error) {
        console.error('Error fetching mission list:', error);
        showMessage('Error fetching mission list');
      }
    };

    fetchMissions();
  }, []);

  // Populate form values if `data` prop is passed (for editing)
  useEffect(() => {
    if (data) {
      Object.keys(data).forEach(key => {
        if (key === "collaborateur") {
          setValue('collaborateur.email', email);
        } else {
          setValue(key, data[key]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, setValue]);

  const onFormSubmit = async (formData) => {
    try {
      // Prepare the FormData for file upload
      const formDataWithFiles = new FormData();
  
      // Append the files to the FormData
      selectedFiles.forEach((file) => {
        formDataWithFiles.append('files', file);
      });
  
      // Upload the files first
      const uploadedFilesResponse = await UploadService.uploadFiles(formDataWithFiles);
  
      // Now prepare the data for creating the note
      const noteData = {
        collaborateur: { email: email },
        date: formData.date,
        mission: formData.mission,
        description: formData.description,
        sejour: formData.sejour,
        deplacement: formData.deplacement,
        repas: formData.repas,
        autres: formData.autres,
        status:'NOTSAVED',
        files: uploadedFilesResponse, // Assuming this returns the list of uploaded files
      };
  
      console.log("noteData",noteData);
      // Call the createNote function with the note data
      await NoteDeFraisService.createNote(noteData);
      
      showMessage('Note created successfully!');
      closeDialog(true);
    } catch (error) {
      console.error('Error creating note:', error);
      showMessage('Error creating note');
    }
  };
  

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setSelectedFiles(prevFiles => [...prevFiles, ...newFiles]);
  };

  const removeFile = (fileName) => {
    setSelectedFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
  };


  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-500 bg-opacity-75">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-lg max-h-[80vh] p-8 overflow-y-auto">
        <h2 className="text-lg font-bold mb-6 text-center">Nouvelle Note </h2>
        <form onSubmit={handleSubmit(onFormSubmit)} email={email} className="space-y-6">
          <div className="space-y-4">
            {/* Date Input */}
            <input
              className={`border rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 ${errors.date ? 'border-red-500' : ''}`}
              type="date"
              {...register('date', { required: 'Date is required' })}
            />
            {errors.date && <p className="text-red-500">{errors.date.message}</p>}

            {/* Mission Select */}
            <select className="border rounded-md p-3 w-full" {...register('mission')}>
            <option value="" disabled selected hidden>Mission</option>
                                    {missions.map((mission, index) => (
                                        <option key={index} value={mission.titre}>{mission.titre}</option>
                                    ))}
            
             </select>
            {errors.mission && <p className="text-red-500">{errors.mission.message}</p>}

            {/* Description TextArea */}
            <textarea
            className={`border rounded-md p-3 w-full ${errors.description ? 'border-red-500' : ''}`}
            placeholder="Description"
            value={watch('description')}  // <-- Set value from the form state
            onChange={(e) => setValue('description', e.target.value)}  // <-- Update form state on change
           ></textarea> 
            {errors.description && <p className="text-red-500">{errors.description.message}</p>}

            {/* Deplacement Input */}
          
              <input
                className={`border rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 ${errors.deplacement ? 'border-red-500' : ''}`}
                placeholder="Déplacement"
                type="number"
                {...register('deplacement', { min: 0 })}
              />
            
            {errors.deplacement && <p className="text-red-500">{errors.deplacement.message}</p>}

            {/* Sejour Input */}
            <input
              className={`border rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 ${errors.sejour ? 'border-red-500' : ''}`}
              placeholder="Séjour"
              type="number"
              {...register('sejour', { required: true, min: 0 })}
            />
            {errors.sejour && <p className="text-red-500">Séjour is required and must be 0 or more</p>}

            {/* Repas Input */}
            <input
              className={`border rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 ${errors.repas ? 'border-red-500' : ''}`}
              placeholder="Repas"
              type="number"
              {...register('repas', { min: 0 })}
            />
            {errors.repas && <p className="text-red-500">Repas must be 0 or more</p>}

            {/* Autres Frais Input */}
            <input
              className="border rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Autres Frais"
              type="number"
              {...register('autres', { min: 0 })}
            />
          </div>

          {/* File Upload */}
          <div className="space-y-2">
            <label htmlFor="fileAttachment" className="cursor-pointer flex items-center space-x-2 text-black-500 hover:text-gray-600">
              <FaPaperclip className="text-xl" />
              <span>Joindre des fichiers</span>
            </label>
            <input
              id="fileAttachment"
              type="file"
              multiple
              className="hidden"
              onChange={handleFileChange}
            />
            {selectedFiles.length > 0 && (
              <ul className="mt-2 space-y-1 max-h-40 overflow-y-auto">
                {selectedFiles.map((file, index) => (
                  <li key={index} className="flex items-center justify-between bg-gray-100 p-2 rounded">
                    <span className="text-sm truncate">{file.name}</span>
                    <button
                      type="button"
                      onClick={() => removeFile(file.name)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <FaTimes />
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </div>

          {/* Action Buttons */}
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={() => closeDialog(false)}
              className="bg-gray-400 hover:bg-gray-500 text-white font-semibold py-2 px-4 rounded-md"
            >
              Annuler
            </button>
            <button
              type="submit"
              className="bg-gray-700 hover:bg-gray-500 text-white font-semibold py-2 px-4 rounded-md"
            >
              Enregistrer
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NoteDeFraisForm;