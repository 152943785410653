import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cards from '../../components/Cards/Cards';

const DashboardPage = () => {
    const [sidebarCollapsed] = useState(true);

    const [notificationCount, setNotificationCount] = useState(0);
    const [missionCount, setMissionCount] = useState(0);
    const [collaboratorCount, setCollaboratorCount] = useState(0);
    const [, setUserRole] = useState('');

    useEffect(() => {
        const role = localStorage.getItem('userRole');
        if (role) {
            setUserRole(role);
        } else {
            console.error('User role not found in localStorage');
        }

        const fetchCounts = async () => {
            try {
                const notificationRes = await axios.get('http://localhost:8077/api/notification/count');
                const missionRes = await axios.get('http://localhost:8096/missions/count');
                const collaboratorRes = await axios.get('http://localhost:8089/api/v1/collaborateur/count');

                setNotificationCount(notificationRes.data);
                setMissionCount(missionRes.data);
                setCollaboratorCount(collaboratorRes.data);
            } catch (error) {
                console.error('Error fetching counts:', error);
            }
        };

        fetchCounts();
    }, []);

    return (
        <div data-testid="dashboard-container" className={`dashboard-container ${sidebarCollapsed ? 'dashboard-collapsed' : ''} p-4 flex flex-col items-center justify-center transition-all duration-500`}>
            <div >
                
            </div>
            <div className="toolbar mb-6 mt-12 text-3xl font-bold text-center text-gray-800">
            <h2>DASHBOARD</h2>
            </div>
            <div className="content-dashboard grid grid-cols-1  gap-4 w-full">
                <Cards
                    notificationCount={notificationCount}
                    missionCount={missionCount}
                    collaboratorCount={collaboratorCount}
                />
            </div>
            
        </div>
    );
};

export default DashboardPage;

