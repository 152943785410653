import React, { useState, useEffect, useRef } from 'react';
import NoteDeFraisForm from '../NoteDeFraisPageForm/NoteDeFraisForm';
import NoteDeFraisService from '../../services/NotesDeFraisService';



const NoteFraisPage = () => {
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isAddEditVisible, setIsAddEditVisible] = useState(false);
  const [editingNote, setEditingNote] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [email, setEmail] = useState('');

  const searchRef = useRef();

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const user = JSON.parse(storedUser);
      if (user.email) {
        setEmail(user.email); 
      } else {
        console.error("User data missing email");
      }
    } else {
      console.error("No user data found in localStorage");
    } 
    }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    fetchNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const fetchNotes = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await NoteDeFraisService.getApprovedNotes(email);
      if (Array.isArray(data) && data.length > 0) {
        setNotes(data);
      } else {
        console.error('No data returned from API');
      }
    } catch (err) {

      console.error('Failed to load Notes:', err);
    } finally {
      setLoading(false);
    }
  };

  

  const handleAddEditModalOpen = (note = null) => {
    setEditingNote(note ? { ...note, collaborateur: { email } } : { collaborateur: { email } });
    setIsAddEditVisible(true);
  };

  const handleAddEditModalClose = (updatedNote) => {
    if (updatedNote) {
      fetchNotes();
    }
    setIsAddEditVisible(false);
    setEditingNote(null);
  };

  const columns = [
    { id: 'noteDeFraisId', label: 'ID' },
    { id: 'date', label: 'Date' },
    { id: 'mission', label: 'Mission' },
    { id: 'description', label: 'Description' },
    { id: 'deplacement', label: 'Déplacement' },
    { id: 'sejour', label: 'Séjour' },
    { id: 'repas', label: 'Repas' },
    { id: 'autres', label: 'Autres' },
    { id: 'total', label: 'Total' },
    { id: 'files', label: 'Fichiers' },
    { id: 'status', label: 'Status'},
    {
      id: 'action',
      label: 'Action',
      render: (note) => (
        <div className="flex space-x-2">
          <button className="text-blue-500 hover:text-blue-700" onClick={() => handleAddEditModalOpen(note)}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path d="M17.414 2.586a2 2 0 010 2.828l-10 10A2 2 0 016 16H4a1 1 0 01-1-1v-2a2 2 0 01.586-1.414l10-10a2 2 0 012.828 0zM15 6L5 16H4v-1l10-10 1 1z" />
            </svg>
          </button>
        </div>
      ),
    },
  ];

  const filteredNotes = notes.filter(note =>
    Object.values(note).some(value => 
      value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center bg-gray-100 p-4 rounded-lg">
        <h1 className="text-2xl font-bold text-gray-700">NOTES DE FRAIS</h1>
        <button className="bg-gray-700 text-white py-2 px-4 rounded" onClick={() => handleAddEditModalOpen()}>
          Nouvelle Note de Frais
        </button>
      </div>

      <div className="mt-4 flex justify-between items-center">
        <input
          ref={searchRef}
          type="text"
          placeholder="Rechercher..."
          value={searchTerm}
          onChange={handleSearch}
          className="border border-gray-300 p-2 rounded w-full"
        />
      </div>

      <div className="mt-4">
        <div className="overflow-auto rounded-lg shadow-lg">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                {columns.map((column) => (
                  <th key={column.id} className="px-4 py-2 bg-gray-800 text-white">
                    {column.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredNotes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((note) => (
                <tr key={note.noteDeFraisId} className="border-t">
                  {columns.map((column) => (
                   <td key={column.id} className="px-4 py-2">
                   {column.id === 'files' ? (
                     Array.isArray(note.filesnames) && note.filesnames.length > 0 ? (
                       <ul className="list-disc pl-5">
                         {note.filesnames.map((filename, index) => (
                           <li key={index} className="mb-2">
                             <div
                               onClick={() => window.open(`http://localhost:8077/api/timesheet/files/${filename}`, '_blank')}
                               className="cursor-pointer text-blue-600 hover:text-blue-800 font-semibold no-underline hover:underline"
                             >
                               {filename}
                             </div>
                           </li>
                         ))}
                       </ul>
                     ) : 'N/A'
                   ) : (
                     note[column.id] != null ? note[column.id] : 'N/A'
                   )}
                 </td>
                  ))}
                </tr>
              ))}
              {filteredNotes.length === 0 && (
                <tr>
                  <td colSpan={columns.length} className="text-center py-4">
                    Pas de notes de frais pour "{searchTerm}"
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="flex justify-between mt-4">
          <div>
            <label>Lignes par page: </label>
            <select
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
              className="border border-gray-300 rounded p-1"
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={25}>25</option>
            </select>
          </div>
          <div>
            <button
              onClick={() => handleChangePage(page - 1)}
              disabled={page === 0}
              className={`px-4 py-2 border rounded ${page === 0 ? 'text-gray-500' : 'text-blue-500'}`}
            >
              Précédent
            </button>
            <button
              onClick={() => handleChangePage(page + 1)}
              disabled={(page + 1) * rowsPerPage >= filteredNotes.length}
              className={`px-4 py-2 border rounded ${((page + 1) * rowsPerPage >= filteredNotes.length) ? 'text-gray-500' : 'text-blue-500'}`}
            >
              Suivant
            </button>
          </div>
        </div>
      </div>

      {isAddEditVisible && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">{editingNote ? 'Modifier la Note' : 'Ajouter une Note'}</h2>
            <NoteDeFraisForm note={editingNote} closeDialog={handleAddEditModalClose} />
            <button
              onClick={() => handleAddEditModalClose()}
              className="mt-4 bg-red-500 text-white py-2 px-4 rounded"
            >
              Fermer
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default NoteFraisPage;