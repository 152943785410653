import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { navbarData } from '../nav-data/nav-data'; 
import SublevelMenu from '../SublevelMenu/SublevelMenu';
import authService from '../../services/AuthService';
import { CiLogout } from "react-icons/ci";

const iconSize = 25;

const Sidebar = ({ onCollapse }) => {
  const userRole = authService.getUserRole();
  const [collapsed, setCollapsed] = useState(false);
  const [expandedItems, setExpandedItems] = useState([]);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
    if (typeof onCollapse === 'function') {
      onCollapse(!collapsed);
    }
  };

  const handleClick = (data) => {
    setExpandedItems((prev) =>
      prev.includes(data.routeLink)
        ? prev.filter((item) => item !== data.routeLink)
        : [...prev, data.routeLink]
    );
  };

  const handleLogout = () => {
    try {
      authService.logout(); // Clear local storage
      window.location.href = '/login'; // Redirect to login page
    } catch (error) {
      console.error('An error occurred during logout:', error);
    }
  };

  return (
    <div className={`flex flex-col bg-gray-800 text-white ${collapsed ? 'w-20' : 'w-64'} transition-all duration-300 overflow-hidden shadow-lg`}>
      <div className="flex items-center pb-4 justify-center">
        <button onClick={toggleSidebar} className='bg-gray-600 text-lg font-bold rounded-full p-2 hover:bg-gray-500 transition duration-200'>
          NTDC
        </button>
      </div>
      <div className="flex-1 overflow-y-auto">
        <ul className="space-y-4">
          {navbarData.filter(data => !data.allowedRoles || data.allowedRoles.includes(userRole)).map((data, index) => (
            <li key={index} className="flex flex-col">
              {data.items ? (
                <button
                  className={`flex items-center p-2 w-full text-left rounded-lg hover:bg-gray-700 transition duration-200 ${expandedItems.includes(data.routeLink) ? 'bg-gray-700' : ''}`}
                  onClick={() => handleClick(data)}
                >
                  {data.icon}
                  <span className={`ml-2 text-base font-medium ${collapsed ? 'hidden' : 'block'}`}>{data.label}</span>
                  <i className={`ml-auto transition-transform ${expandedItems.includes(data.routeLink) ? 'fas fa-angle-down' : 'fas fa-angle-right'}`}></i>
                </button>
              ) : (
                <Link
                  to={`/${data.routeLink}`}
                  className={`flex items-center p-2 w-full text-left rounded-lg hover:bg-gray-700 transition duration-200 ${window.location.pathname === `/${data.routeLink}` ? 'bg-gray-700' : ''}`}
                >
                  {data.icon}
                  <span className={`ml-2 text-base font-medium ${collapsed ? 'hidden' : 'block'}`}>{data.label}</span>
                </Link>
              )}
              {data.items && expandedItems.includes(data.routeLink) && (
                <SublevelMenu data={data} collapsed={collapsed} />
              )}
            </li>
          ))}
            <li className="flex items-center p-2 w-full text-left">
              <button
                className="flex items-center w-full"
                onClick={handleLogout}
              >
                <CiLogout size={iconSize} />
                <span className={`ml-2 ${collapsed ? 'hidden' : 'block'}`}>Se Déconnecter</span>
              </button>
            </li>

        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
