import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = ({ passwordService }) => {
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();

  const handleForgotPassword = () => {
    passwordService.forgotPassword(email).then(
      (response) => {
        if (response && typeof response === 'object') {
          setSuccessMessage(response.message || 'Un email pour réinitialiser votre mot de passe a été envoyé à votre adresse email.');
          setErrorMessage(null);
        } else if (typeof response === 'string') {
          setSuccessMessage(null);
          setErrorMessage(response);
        } else {
          setSuccessMessage(null);
          setErrorMessage('Unexpected response format');
        }
      },
      (error) => {
        setSuccessMessage(null);
        if (error.status === 404) {
          setErrorMessage('User not found');
        } else {
          setErrorMessage('ERROR');
        }
      }
    );
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-50 to-white">
      <div className="flex flex-col md:flex-row bg-white shadow-lg rounded-lg p-8 w-full max-w-md md:max-w-2xl">
        <div className="hidden md:block w-1/2">
          <img
            aria-hidden="false"
            className="w-full h-auto"
            src="/img/undraw_forgot_password_re_hxwm.svg"
            alt="Forgot password"
          />
        </div>
        <div className="w-full md:w-1/2 p-6">
          <h1 className="text-3xl font-bold mb-4 text-gray-800">Mot de passe oublié ?</h1>
          <p className="text-gray-600 mb-6">
            Veuillez entrer votre adresse e-mail.
          </p>
          <input
            className="border border-gray-300 p-3 rounded-lg w-full mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            required
          />
          <button
            onClick={handleForgotPassword}
            className="bg-blue-500 text-white font-semibold py-2 px-4 rounded-lg w-full mb-4 hover:bg-blue-600 transition duration-300"
          >
            Récupérer le mot de passe
          </button>
          {successMessage && <div className="text-green-500 mb-4">{successMessage}</div>}
          {errorMessage && <div className="text-red-500 mb-4">{errorMessage}</div>}
          <button
            onClick={() => navigate('/login')}
            className="bg-gray-400 hover:bg-gray-500 text-white font-semibold py-2 px-4 rounded-md "
          >
            Annuler
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
