// src/pages/Register.js
import React, { useState } from 'react';
import authService from '../../services/AuthService';
import { useNavigate } from 'react-router-dom';

const RegisterPage = () => {
    const [email, setEmail] = useState('');
    const [motDePasse, setMotDePasse] = useState('');
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [numTel, setNumTel] = useState('');
    const [role, setRole] = useState(''); 
    const [isRegistrationFailed, setIsRegistrationFailed] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);
  
    const navigate = useNavigate();
 
    const handleSubmit = async (e) => {
        e.preventDefault();
       
        if (!email || !motDePasse || !nom || !prenom || !numTel || !role) {
            setIsRegistrationFailed(true);
            setErrorMessage("Veuillez remplir tous les champs");
            return;
          }

        setSubmitted(true);
        try {
          const requestData = {
            prenom,
            nom,
            email,
            motDePasse,
            numTel,
            role
          };
          console.log("request", requestData)
           await authService.register(requestData);
           setIsRegistrationFailed(false);
            setSuccessMessage("Un email a été envoyé. Veuillez vérifier votre boîte de réception.");
         
        } catch (error) {
            setErrorMessage(error.response?.data?.message || 'Echec d\'inscription. Veuillez réessayez.');
            setIsRegistrationFailed(true);
        } finally {
            setSubmitted(false);
        }
      };
    return (
      <div className="bg-white min-h-screen flex items-center justify-center">
        <div className="container mx-auto p-4 max-w-screen-lg flex flex-col items-center justify-center">
          <div className="flex flex-col justify-center items-center text-center">
            <form onSubmit={handleSubmit} noValidate className="w-full">
              <img src="/img/logoTime.jpg" alt="Logo" className="h-32 mx-auto" />
              <h2 className="text-3xl font-bold text-gray-800 my-4">Bienvenue</h2>
              <div className="relative mb-6">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <i className="fas fa-user text-gray-400"></i>
                </div>
                <input
                  type="text"
                  className="w-full pl-10 pr-4 py-2 border-b-2 border-gray-300 focus:border-blue-500 outline-none"
                  id="nom"
                  value={nom}
                   data-testid="nom"
                  onChange={(e) => setNom(e.target.value)}
                  required
                  placeholder="Nom"
                />
                {submitted && nom === '' && (
                  <div className="text-red-500 text-sm mt-1" role="alert">
                    <span>Le nom est requis!</span>
                  </div>
                )}
              </div>
              <div className="relative mb-6">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <i className="fas fa-user text-gray-400"></i>
                </div>
                <input
                  type="text"
                  className="w-full pl-10 pr-4 py-2 border-b-2 border-gray-300 focus:border-blue-500 outline-none"
                  id="prenom"
                  value={prenom}
                  onChange={(e) => setPrenom(e.target.value)}
                  required
                  placeholder="Prenom"
                />
                {submitted && prenom === '' && (
                  <div className="text-red-500 text-sm mt-1" role="alert">
                    <span>Le prénom est requis!</span>
                  </div>
                )}
              </div>
              <div className="relative mb-6">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <i className="fas fa-user text-gray-400"></i>
                </div>
                <input
                  type="email"
                  className="w-full pl-10 pr-4 py-2 border-b-2 border-gray-300 focus:border-blue-500 outline-none"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  placeholder="Email"
                />
                {submitted && email === '' && (
                  <div className="text-red-500 text-sm mt-1" role="alert">
                    <span>L'email est requis!</span>
                  </div>
                )}
              </div>
              <div className="relative mb-6">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <i className="fas fa-user text-gray-400"></i>
                </div>
                <input
                  type="text"
                  className="w-full pl-10 pr-4 py-2 border-b-2 border-gray-300 focus:border-blue-500 outline-none"
                  id="numTel"
                  value={numTel}
                  onChange={(e) => setNumTel(e.target.value)}
                  required
                  placeholder="Téléphone"
                />
                {submitted && numTel === '' && (
                  <div className="text-red-500 text-sm mt-1" role="alert">
                    <span>Le numéro de téléphone est requis!</span>
                  </div>
                )}
              </div>
              <div className="relative mb-6">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <i className="fas fa-user text-gray-400"></i>
                </div>
                <input
                  type="text"
                  className="w-full pl-10 pr-4 py-2 border-b-2 border-gray-300 focus:border-blue-500 outline-none"
                  id="role"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  required
                  placeholder="Role"
                />
                {submitted && role === '' && (
                  <div className="text-red-500 text-sm mt-1" role="alert">
                    <span>Le role est requis!</span>
                  </div>
                )}
              </div>
              <div className="relative mb-6">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <i className="fas fa-lock text-gray-400"></i>
                </div>
                <input
                  type="password"
                  className="w-full pl-10 pr-4 py-2 border-b-2 border-gray-300 focus:border-blue-500 outline-none"
                  id="motDePasse"
                  value={motDePasse}
                  onChange={(e) => setMotDePasse(e.target.value)}
                  required
                  minLength="6"
                  placeholder="Mot de passe"
                />
                {submitted && (
                  <div className="text-red-500 text-sm mt-1" role="alert">
                    {motDePasse === '' && <div>Mot de passe est requis</div>}
                    {motDePasse.length < 6 && <div>Le mot de passe doit comporter au moins 6 caractères</div>}
                  </div>
                )}
              </div>
              <div className="mb-4">
                <button className="w-full py-2 bg-blue-500 text-white font-bold rounded hover:bg-blue-600 transition duration-300" type="submit">S'inscrire</button>
              </div>
              <div> <button type="button"onClick={() => navigate('/login')}className="text-blue-500 hover:underline">Annuler</button></div>
              {successMessage && (
            <div className="text-green-500 text-sm mt-1" role="alert">
                <span>{successMessage}</span>
            </div>
           )}
              {isRegistrationFailed && (
                <div className="text-red-500 text-sm mt-1" role="alert" aria-live="assertive">
                  <span>{errorMessage}</span>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    );
  };

export default RegisterPage;
