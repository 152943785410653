import React, { useEffect, useState } from 'react';
import AdminService from '../../../services/AdminService';

const AdminAddEditForm = ({ admin, visible, onClose }) => {
  const [formData, setFormData] = useState({
    adminPrenom: '',   
    adminNom: '',     
    adminEmail: '',    
    adminNumTel: '',  
    adminMotDePasse: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (admin) {
      setFormData({
        adminPrenom: admin.utilisateur.prenom,  
        adminNom: admin.utilisateur.nom,        
        adminEmail: admin.utilisateur.email,    
        adminNumTel: admin.utilisateur.numTel,  
        adminMotDePasse: '' 
      });
    } else {
      setFormData({
        adminPrenom: '',   
        adminNom: '',
        adminEmail: '',
        adminNumTel: '',
        adminMotDePasse: ''
      });
    }
  }, [admin]);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      if (admin) {
        await AdminService.updateAdmin(admin.id, formData);
        alert('Admin updated successfully');
      } else {
        await AdminService.addAdmin(formData);
        alert('Admin added successfully');
      }
      onClose(true);
    } catch (error) {
      console.error('Error saving admin:', error);
      setError(error.response?.data?.message || 'An unexpected error occurred');
    } finally {
      setLoading(false);
    }
  };

  if (!visible) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-500 bg-opacity-75">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-lg max-h-[80vh] p-8 overflow-y-auto">
        <h2 className="text-xl font-semibold mb-4">{admin ? 'Modifier Admin' : 'Ajouter Admin'}</h2>
        {error && <div className="text-red-500 mb-4">{error}</div>}
        <form onSubmit={handleSubmit}>
        <div className="mb-4">
            <label className="block text-sm font-medium mb-1" htmlFor="adminPrenom">Prénom</label>
            <input
              id="adminPrenom"
              type="text"
              name="adminPrenom"
              value={formData.adminPrenom}
              onChange={handleChange}
              required
              className="border border-gray-300 p-2 rounded w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1" htmlFor="adminNom">Nom</label>
            <input
              id="adminNom"
              type="text"
              name="adminNom"
              data-testid="admin-nom"
              value={formData.adminNom}
              onChange={handleChange}
              required
              className="border border-gray-300 p-2 rounded w-full"
            />
       </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1" htmlFor="adminEmail">Email</label>
            <input
              id="adminEmail"
              type="email"
              name="adminEmail" // Updated to match formData key
              value={formData.adminEmail}
              onChange={handleChange}
              required
              className="border border-gray-300 p-2 rounded w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1" htmlFor="adminNumTel">Téléphone</label>
            <input
              id="adminNumTel"
              type="tel"
              name="adminNumTel" // Updated to match formData key
              value={formData.adminNumTel}
              onChange={handleChange}
              required
              className="border border-gray-300 p-2 rounded w-full"
            />
          </div>
          {!admin && (
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1" htmlFor="adminMotDePasse">Mot de passe</label>
              <input
                id="adminMotDePasse"
                type="password"
                name="adminMotDePasse" // Updated to match formData key
                value={formData.adminMotDePasse}
                onChange={handleChange}
                required
                className="border border-gray-300 p-2 rounded w-full"
              />
            </div>
          )}
          <div className="flex justify-end">
            <button
              type="button"
              onClick={() => onClose(false)}
              className="ml-2 border bg-gray-400 px-4 py-2 rounded hover:bg-gray-200 transition"
            >
              Annuler
            </button>
            <button
              type="submit"
              className="bg-gray-700 text-white px-4 py-2 rounded hover:bg-gray-800 transition"
              disabled={loading}
            >
              {loading ? 'Enregistrement...' : (admin ? 'Modifier' : 'Enregistrer')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminAddEditForm;
