import axios from 'axios';

const API_URL = 'http://localhost:8089/api/v1/supplieradmin';

// Function to get the auth token from localStorage
const getAuthToken = () => {
  return localStorage.getItem('authToken');
};

// Service object for SupplierAdmin API operations
const SupplierAdminService = {
  // Fetch all Supplier Admins
  getSupplierAdmins: async () => {
    try {
      const response = await axios.get(`${API_URL}/getall`, {
        headers: {
          'Authorization': `Bearer ${getAuthToken()}`,
        },
        withCredentials: true, // Include credentials like cookies in the request
      });

      // Modify the response mapping to the desired structure
      return response.data.map(superAdmin => ({
        id: superAdmin.id,
        prenom: superAdmin.supplierAdminPrenom,
        nom: superAdmin.supplierAdminNom,
        email: superAdmin.supplierAdminEmail,
        numTel: superAdmin.supplierAdminNumTel,
        companyId: superAdmin.companyId,
        companyNom: superAdmin.company ? superAdmin.company.nom : 'N/A',
      }));
    } catch (error) {
      console.error("Error fetching supplier admins:", error);
      throw error; // Rethrow the error to handle it in the calling function
    }
  },

  // Add a new Supplier Admin
  addSupplierAdmin: (admin) => axios.post(`${API_URL}/add`, admin, {
    headers: {
      'Authorization': `Bearer ${getAuthToken()}`,
    },
    withCredentials: true,
  }),

  // Update an existing Supplier Admin
  updateSupplierAdmin: (id, admin) => axios.put(`${API_URL}/update/${id}`, admin, {
    headers: {
      'Authorization': `Bearer ${getAuthToken()}`,
    },
    withCredentials: true,
  }),

  // Delete a Supplier Admin by ID
  deleteSupplierAdmin: (id) => axios.delete(`${API_URL}/delete/${id}`, {
    headers: {
      'Authorization': `Bearer ${getAuthToken()}`,
    },
    withCredentials: true,
  }),
};

export default SupplierAdminService;
