import React, { useState, useEffect, useRef } from "react";
import SupplierAdminAddEditForm from './SupplierAdminAddEditForm'; // Import the form component
import axios from "axios";
const SupplierAdminPage = () => {
  const [superAdmins, setSuperAdmins] = useState([]); // Ensure this is initialized as an empty array
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [currentSuperAdmin, setCurrentSuperAdmin] = useState(null);
  const [filter, setFilter] = useState("");
  const searchRef = useRef();
  const [editMode, setEditMode] = useState(false);
  
  useEffect(() => {
    fetchSuperAdmin();
  }, []);

  const fetchSuperAdmin = async () => {
    setLoading(true);
    try { 
      const response = await axios.get("http://localhost:8089/api/v1/supplieradmin/getall");
      setSuperAdmins(response.data);  
    } catch (error) {
      console.error("Error fetching Supplier Admins:", error);
      alert("Failed to fetch supplier admins");
    } finally {
      setLoading(false);
    }
  };
  
  const handleAddEditModalOpen = () => {
    setCurrentSuperAdmin({}); // For adding a new SuperAdmin
    setEditMode(false);
    setOpen(true);
  };
  
  const handleEdit = (superAdmin) => {
    setCurrentSuperAdmin(superAdmin); // For editing a SuperAdmin
    setEditMode(true); // Set editMode to true for editing
    setOpen(true);
  };

  const handleClose = (refresh = false) => {
    setOpen(false);
    if (refresh) {
      fetchSuperAdmin();
    }
  };
  const handleDelete = async (id) => {
    try {
      await axios.delete(`http://localhost:8089/api/v1/supplieradmin/delete/${id}`);
      fetchSuperAdmin();
      alert("SupplierAdmin supprimé avec succès");
    } catch (error) {
      console.error("Erreur lors de la suppression du SupplierAdmin:", error);
      alert("Erreur lors de la suppression du supplier admin");
    }
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredSuperAdmins = superAdmins.filter((superAdmin) => {
    return (
      (superAdmin.supplierAdminPrenom || '').toLowerCase().includes(filter.toLowerCase()) ||
      (superAdmin.supplierAdminNom || '').toLowerCase().includes(filter.toLowerCase()) ||
      (superAdmin.supplierAdminEmail || '').toLowerCase().includes(filter.toLowerCase()) ||
      (superAdmin.supplierAdminNumTel || '').toLowerCase().includes(filter.toLowerCase())
    );
  });

  return (
    <div className="flex flex-col p-5">
      {loading ? (
        <div className="flex justify-center items-center h-full">
          <span className="loader"></span> {/* Add your loading spinner here */}
        </div>
      ) : (
        <>
          <div className="flex justify-between items-center bg-gray-100 p-4 rounded-lg">
            <h2 className="text-2xl font-bold text-gray-700">Supplier Admins</h2>
            <button
              onClick={handleAddEditModalOpen}
              className="bg-gray-700 text-white p-2 rounded hover:bg-blue-600"
            >
              Nouveau Supplier Admin
            </button>
          </div>
          <div className="mb-5 flex items-center">
            <input
              ref={searchRef}
              placeholder="Rechercher par nom ou entreprise"
              className="border border-gray-300 p-2 rounded w-full"
              onChange={handleFilterChange}
            />
          </div>
          <div className="overflow-y-auto max-h-[calc(100vh-100px)]">
            <table className="min-w-full border-collapse border border-gray-200">
              <thead>
                <tr className="bg-gray-100" >
                  <th className="border border-gray-300 p-2">ID</th>
                  <th className="border border-gray-300 p-2">Prénom</th>
                  <th className="border border-gray-300 p-2">Nom</th>
                  <th className="border border-gray-300 p-2">Email</th>
                  <th className="border border-gray-300 p-2">Téléphone</th>
                  <th className="border border-gray-300 p-2">Entreprise</th> {/* Column for Entreprise */}
                  <th className="border border-gray-300 p-2">Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredSuperAdmins.map((superAdmin) => (
                  <tr key={superAdmin.id} className="border-b hover:bg-gray-300">
                     <td className=" border border-gray-300 p-2">{superAdmin.id}</td> 
                    <td className=" border border-gray-300 p-2">{superAdmin.utilisateur.prenom}</td> 
                    <td className=" border border-gray-300 p-2">{superAdmin.utilisateur.nom || 'N/A'}</td>  
                    <td className=" border border-gray-300 p-2">{superAdmin.utilisateur.email || 'N/A'}</td> 
                    <td className=" border border-gray-300 p-2">{superAdmin.utilisateur.numTel|| 'N/A'}</td>
                    <td className=" border border-gray-300 p-2">{superAdmin.companyId ? superAdmin.companyId.nom : 'N/A'}</td>
                    <td className=" border border-gray-300 p-2">
                      <button
                        onClick={() => handleEdit(superAdmin)}
                        className="text-blue-500 hover:bg-blue-100 p-2 rounded"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.828 4.586l4.586 4.586-1.414 1.414-4.586-4.586 1.414-1.414zM3 21v-3.586l10-10 3.586 3.586-10 10H3z" />
                        </svg>
                      </button>
                      <button
                        className="text-red-500 ml-2"
                        onClick={() => handleDelete(superAdmin.id)}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7L7 19M7 7l12 12" />
                          </svg>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {open && (
            <SupplierAdminAddEditForm
              SuperAdmin={currentSuperAdmin}
              editMode={editMode}
              onClose={handleClose}
            />
          )}
        </>
      )}
    </div>
  );
};

export default SupplierAdminPage;