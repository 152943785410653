import axios from 'axios';

const API_URL = 'http://localhost:8089/api/v1/admin'; // Replace with your API URL

const getAuthToken = () => {
  return localStorage.getItem('authToken');
};

const AdminService = {
  getAdmins: () => axios.get(`${API_URL}/getall`, {
    headers: {
      'Authorization': `Bearer ${getAuthToken()}`,
    },
  }),
  addAdmin: (admin) => axios.post(`${API_URL}/add`, admin, {
    headers: {
      'Authorization': `Bearer ${getAuthToken()}`,
    },
  }),
  updateAdmin: (id, admin) => axios.put(`${API_URL}/update/${id}`, admin, {
    headers: {
      'Authorization': `Bearer ${getAuthToken()}`,
    },
  }),
  deleteAdmin: (id) => axios.delete(`${API_URL}/delete/${id}`, {
    headers: {
      'Authorization': `Bearer ${getAuthToken()}`,
    },
  }),
};

export default AdminService;
