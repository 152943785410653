// passwordService.js
export const passwordService = {
    forgotPassword: (email) => {
      return fetch('http://localhost:9500/password/forgotpassword', {
        method: 'POST',
        headers: {

          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      })
        .then((response) => {
          if (!response.ok) {
            return Promise.reject({ status: response.status });
          }
          return response.json();
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  };
  